<div class="modal show modal-flash-sale promo-room-1">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<div class="modal-body">
					<h3 class="popup-title">{{title}}</h3>
					<p class="popup-desc terms-condition">Terms & Conditions</p>
					<p class="popup-desc ">{{description}}</p>
				</div>
			</div>
		</div>
	</div>