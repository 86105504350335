 <!-- stay-connected -->
 <div class="newsletter-container py-5 container-fluid">
  <div class="container py-5">
    <div class="row align-items-end">
      <div class="col-md-8">
        <!-- <h3 class="mb-md-5 mb-sm-3">
          <sapn translate *ngIf="continent_flag == 'Gulf'">Resorts World Cruises - The World-Class Cruise Line that Knows Gulf Best</sapn>
          <sapn translate *ngIf="continent_flag == 'Asia'">Resorts World Cruises - The World-Class Cruise Line that Knows Asia Best</sapn>
        </h3> -->
        <p *ngIf="LanguageId == 1" translate>
          At Resorts World Cruises, we bring the classic resort experience to
          <br class="isDesktop" translate> 
          you even while at sea. Enjoy elevated experience while you explore
          <br class="isDesktop" translate> 
          all that Southeast Asia has to offer.
        </p>
        <p *ngIf="LanguageId == 2" translate>
          在名勝世界郵輪，即使在海上，我們也能為您帶
          <br class="isDesktop" translate> 
          來最佳的度假體驗。 在您探索東南亞的所有景點
          <br class="isDesktop" translate> 
          時享受難忘的體驗。 
        </p>
        <p *ngIf="LanguageId == 3" translate>
          在名胜世界邮轮，即使在海上，我们也能为您带
          <br class="isDesktop" translate> 
          来最佳的度假体验。在您探索东南亚的所有景点
          <br class="isDesktop" translate> 
          时享受难忘的体验。
        </p>
        <p *ngIf="LanguageId == 4" translate>
          نوفر لك في ريزورتس ورلد كروزيس تجربة المنتجع الكلاسيكية
          <br class="isDesktop" translate>
          حتى وأنت في البحر. استمتع بتجربة راقية بينما تستكشف
          <br class="isDesktop" translate>
          كل ما تقدمه لك منطقة جنوب شرق آسيا.
        </p>
      </div>
      <div class="col-md-4" [ngClass]="{'text-start text-md-end':LanguageId != '4', 'text-end text-md-start':LanguageId == '4'}">
        <h4 class="mb-3 mt-5 mt-md-0" translate>Stay Connected</h4>
        <ul class="socials">
          <li><a href="https://www.facebook.com/resortsworldcruises" target="_blank" (click)="handleClick($event, 'https://www.facebook.com/resortsworldcruises','blank')"><i class="fa-brands"> <ion-icon name="logo-facebook"></ion-icon></i></a></li>
          <li><a href="https://www.instagram.com/resortsworldcruises/" target="_blank" (click)="handleClick($event, 'https://www.instagram.com/resortsworldcruises/','blank')"><i class="fa-brands"><ion-icon name="logo-instagram"></ion-icon></i></a></li>
          <li><a href="https://www.tiktok.com/@resortsworldcruises" target="_blank" (click)="handleClick($event, 'https://www.tiktok.com/@resortsworldcruises','blank')"><i class="fa-brands"><ion-icon name="logo-tiktok"></ion-icon></i></a></li>
          <li><a href="https://t.me/resortsworldcruises" target="_blank" (click)="handleClick($event, 'https://t.me/resortsworldcruises','blank')"><i class="fa-brands"><ion-icon name="navigate-circle"></ion-icon></i></a></li>
          <li><a href="https://www.youtube.com/@resortsworldcruises." target="_blank" (click)="handleClick($event, 'https://www.youtube.com/@resortsworldcruises.','blank')"><i class="fa-brands"><ion-icon name="logo-youtube"></ion-icon></i></a></li>
          <li><a href="https://www.linkedin.com/company/resorts-world-cruises" target="_blank" (click)="handleClick($event, 'https://www.linkedin.com/company/resorts-world-cruises','blank')"><i class="fa-brands"><ion-icon name="logo-linkedin"></ion-icon></i></a></li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- end:stay-connected -->

<!-- footer form-->
<div class="container-fluid py-5 footer">
  <form class="newsletter px-md-2 px-3" #NewsLetterForm="ngForm" (ngSubmit)="subscribe(NewsLetterForm)">
    <div class="container py-4 ">
      <div class="row align-items-center">
        <div class="col-md-2 mb-3 mb-md-0">
          <p class="m-0" translate>SUBSCRIBE TO OUR NEWSLETTER</p>
        </div>

        <div class="col-md-4 mb-3 mb-md-0 ">
          <input type="email" name="email" placeholder="{{'Email' | translate}}" #email="ngModel" [(ngModel)]='newsEmail' required>
          <div  *ngIf="NewsLetterForm.submitted && NewsLetterForm.controls['email']?.hasError('required')" class="error" translate>
            <fa-icon icon="exclamation-triangle"></fa-icon> {{'Please fill in this field.' | translate}}
          </div>
        </div>

        <div class="col-md-4 mb-4 mb-md-0">
          <!-- <ngx-intl-tel-input [preferredCountries]="['us', 'gb', 'de']" [enableAutoCountrySelect]="true" name="country" #country="ngModel" [(ngModel)]="newsCountry"></ngx-intl-tel-input> -->
          <!-- <input id="countrySelector" name="country-of-residence"  type="text"> -->
         
          <!-- <ion-select class="borderBottom" placeholder="" interface="popover" name="country" #country="ngModel" [(ngModel)]='newsCountry' required>
            <ion-select-option class="custom-select-popover" *ngFor="let code of countryList" [value]="code.name">
              {{code.flag}} {{code.name}}
            </ion-select-option>
          </ion-select> -->

          <form [formGroup]="phoneForm" class="intl-input-form">
            <ngx-intl-tel-input bdi dir="ltr"
              [cssClass]="'custom-intel-input'"
              [enableAutoCountrySelect]="true"
              [enablePlaceholder]="false"
              [searchCountryFlag]="false"
              [selectFirstCountry]="false"
              [selectedCountryISO]="flageCode"
              [preferredCountries]="preferredCountries"
              name="phone"
              (countryChange)="onCountryChange($event)"
              [(ngModel)]="newsCountry"
              formControlName="phone">
            </ngx-intl-tel-input>
          </form>

          <!-- <div *ngIf="NewsLetterForm.submitted && NewsLetterForm.controls['country']?.hasError('required')" class="error">
            Please fill in this field.
          </div> -->
        </div>
        <!-- <div class="col-md-4 mb-4 mb-md-0">
          <div class="d-flex">
            <div>
              <ion-select class="borderBottom" placeholder="" interface="popover" name="country" #country="ngModel" [(ngModel)]='newsCountry' [selectedText]="NewsLetterForm?.value?.country?.flag" required>
                <ion-select-option class="custom-select-popover" *ngFor="let code of countryList" [value]="code">
                  {{code.flag}} {{code.name}}
                </ion-select-option>
              </ion-select>
            </div>
            <div class="col-md-9">
              <input type="text" name="country" [(ngModel)]='newsCountry.name' [readonly]="true">
            </div>
          </div>
        </div> -->

        <div class="col-md-2">
          <ion-button type="submit" fill="clear" class="subs-button" translate>Subscribe</ion-button>
        </div>
      </div>
    </div>
  </form>
  <div class="container menu-items mt-5 footer-menu">
    <div class="row align-items-start">
      <div class="col-md-2 col-6"  *ngFor="let menu of menus">
        <p><strong>{{menu.menu_name}}</strong></p>
        <ul>
          <li *ngFor="let submenu of menu.menu"><a [href]="submenu.url" (click)="handleClick($event, submenu.url)">{{submenu.menu_name}}</a></li>
        </ul>
      </div>
      <div class="col-md-2 col-6">
        <!-- <p class="d-block"><a [href]="bookCruise"><strong translate>Book A Cruise</strong></a></p> -->
        <p class="d-block"><a [href]="contactUs" (click)="handleClick($event, contactUs)"><strong  translate>Contact Us</strong></a></p>
        <p class="d-block"><a [href]="careers" (click)="handleClick($event, careers)"><strong translate>Careers</strong></a></p>
        <p class="d-block"><a [href]="mediaResources" (click)="handleClick($event, mediaResources)"><strong translate>Media & Resources</strong></a></p>
        <p class="d-block"><a [href]="partnerResource" target="_blank" (click)="handleClick($event, partnerResource, 'blank')"><strong translate>Partner Resource Center</strong></a></p>
      </div>
    </div>
  </div>
</div>
<!-- footer -->
<div class="container-fluid footer-bottom border-top">
  <div class="container my-3">
    <div class="row align-items-center">
      <div class="col-md-4 text-center" [ngClass]="{'text-md-start':LanguageId != '4', 'text-md-end':LanguageId == '4'}">
        <p translate>© 2024 Resorts World Cruises. All rights reserved. </p>
      </div>
      <div class="col-md-8 text-center mt-3 mt-md-0" [ngClass]="{'text-md-end':LanguageId != '4', 'text-md-start':LanguageId == '4'}">
        <ul>
          <li><a [href]="billsOfRight" (click)="handleClick($event, billsOfRight)" translate>Bill of Rights</a></li>
          <li><a [href]="privacyPolicy" (click)="handleClick($event, privacyPolicy)" translate>Privacy Policy</a></li>
          <li><a [href]="cookiePolicy" (click)="handleClick($event, cookiePolicy)" translate>Cookie Policy</a></li>
          <li><a [href]="termsOfUse" (click)="handleClick($event, termsOfUse)" translate>Terms of Usage</a></li>
          <li><a [href]="passageTerms" (click)="handleClick($event, passageTerms)" translate>Passage Terms</a></li>
          <li><a [href]="termsandconditions" (click)="handleClick($event, termsandconditions)" translate>Terms & Conditions</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- end: footer -->