<div id="header">
  <!-- navbar -->
  <div *ngIf="!isBelowLg" class="container-fluid p-0 topNavBar isDesktop">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6 col-empty"></div>
        <div class="col-md-6 topNavBarHolder text-end p-0">
          <ul>
            <li class="lang-toggle"><a href="#" class="lang-switcher-toggle" (click)="openLangModal($event)"><img
                  src="assets/trans-icon.png" alt="translation"/></a></li>
            <li><a [href]="contactUs" (click)="handleClick($event,contactUs)" translate>CONTACT US</a></li>
            <li>
              <a href="#" class="dropdown-toggle" (click)="handleClick($event)" translate>MANAGE &ensp;<span class="caret"></span></a>
              <ul class="drop-down-items">
                <li class="child-menu bd-highlight"><a [href]="checkin" target="_blank" (click)="handleClick($event,checkin,'blank')">{{'Check In' | translate}}</a></li>
                <li class="child-menu bd-highlight"><a [href]="knowledgeBook" (click)="handleClick($event,knowledgeBook)">{{'Know Before You Sail' | translate}}</a></li>
                <li class="child-menu bd-highlight"><a [href]="cruisePrepare" (click)="handleClick($event,cruisePrepare)">{{'Prepare For Your Cruise' | translate}}</a></li>
              </ul>
            </li>

            <li><a [href]="genting_reward" target="_blank" (click)="handleClick($event,genting_reward,'blank')" translate>GENTING REWARDS</a>
              <!-- <a [href]="genting_reward" target="_blank"><img src="assets/home-banner/Genting-reward_logo2x.png"
                  alt="Genting Rewards" class="gr" /></a> -->
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- end:navbar -->

  <!-- desktop menu items -->
  <div *ngIf="!isBelowLg" class="container-fluid stickyTop isDesktop" [ngClass]="{'fixed': isFixed}">
    <div class="container">
      <div class="row ">
        <div class="col-md-12 mainNavBar">
          <div class="d-flex align-items-center bd-highlight">
            <div class="flex-grow-1 bd-highlight">
              <a href="#" (click)="refreshPage($event)">
                <img [src]="logoURL" alt="logo" class="logo"/>
              </a>
            </div>

            <div class="child-menu bd-highlight has-sub" *ngFor="let menu of menus">
              <a href="#" (click)="handleClick($event)">{{menu.menu_name}}</a>
              <div class="drop-down-items">
                <div class="child-menu bd-highlight" *ngFor="let submenu of menu.menu"><a
                    [href]="submenu.url.length > 1 ? submenu.url : submenu.url" (click)="handleClick($event, submenu.url)">{{submenu.menu_name}}</a></div>
              </div>
            </div>

            <!-- <div class="child-menu bd-highlight"><a [href]="bookCruise">{{'Book A Cruise' | translate}}</a></div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end:desktop menu items -->

  <!-- mobile menu items -->
  <div *ngIf="isBelowLg" class="container-fluid stickyTop isMobile" [ngClass]="{'fixed': isFixed}">
    <div class="row align-items-center mobile-menu">
      <div class="col">
        <div class="d-flex align-items-center bd-highlight">
          <div class="flex-grow-1 bd-highlight" [ngClass]="{'text-end': selectedLanuageId === '4', 'text-start' : selectedLanuageId != '4'}">
            <a class="px-2" href="#" (click)="refreshPage($event)">
              <img [src]="logoURL" alt="logo" class="logo" />
            </a>
          </div>

          <div class="py-4 bd-highlight">
            <a href="#" class="lang-switcher-toggle" (click)="openLangModal($event)"><img
              src="assets/trans-icon.png" alt="trans-icon" class="lang-logo"/></a>
            <span class="mobile-menu-toggle pr-3"><fa-icon icon="bars" (click)="openMobileMenu()"></fa-icon></span>
          </div>
        </div>
      </div>
    </div>
  
    <!-- mobile menu items -->
    <div *ngIf="showMobileMenu" class="mobile-menu-holder px-3">
      <div class="d-flex align-items-center bd-highlight mb-5">
        <div class="flex-grow-1 px-1 bd-highlight text-start">
          <a href="#" (click)="refreshPage($event)">
          <img [src]="logoURL" alt="logo" class="logo" />
          </a>
        </div>

        <div class="py-4 bd-highlight">
          <span class="mobile-menu-close-toggle pr-3"><fa-icon icon="close" (click)="closeMobileMenu()"></fa-icon></span>
        </div>
      </div>

      <div class="mobile-menu-items">
        <div class="child-menu bd-highlight has-sub" *ngFor="let menu of menus">
          <a href="#" (click)="handleClick($event)">{{menu.menu_name}}</a>
          <fa-icon icon="caret-down" class="drop-down" (click)="handleChange(menu.menu_name)"></fa-icon>
          <div class="drop-down-items isDesktop" *ngIf="menu.selectedMenu">
            <div *ngFor="let submenu of menu.menu" class="child-menu bd-highlight">
              <a [href]="submenu.url" (click)="handleClick($event,submenu.url)">{{submenu.menu_name}}</a>
            </div>
          </div>
        </div>
        <!-- <div class="child-menu bd-highlight"><a [href]="bookCruise" translate>Book A Cruise</a></div> -->
        <div class="child-menu bd-highlight"><a [href]="contactUs" (click)="handleClick($event,contactUs)" translate>Contact Us</a></div>
        <div class="child-menu has-sub bd-highlight">
          <a href="#" (click)="handleClick($event)" translate>Manage</a>
          <fa-icon icon="caret-down" class="drop-down" (click)="handleMenuManage()"></fa-icon>
          <div class="drop-down-items isDesktop" *ngIf="displayManage">
            <div class="child-menu bd-highlight"><a [href]="checkin" target="_blank" (click)="handleClick($event,checkin,'blank')" translate>Check In</a></div>
            <div class="child-menu bd-highlight"><a [href]="knowledgeBook" (click)="handleClick($event,knowledgeBook)" translate>Know Before You Sail</a></div>
            <div class="child-menu bd-highlight"><a [href]="cruisePrepare" (click)="handleClick($event,cruisePrepare)" translate>Prepare For Your Cruise</a></div>
          </div>
        </div>
        <div class="child-menu bd-highlight"><a [href]="genting_reward" target="_blank" (click)="handleClick($event,genting_reward,'blank')" translate>Genting Rewards</a></div>
      </div>
    </div>
    <!-- end:mobile menu items -->

  </div>
  <!-- end:mobile menu items -->
   
  <div *ngIf="showLang" class="lang-switcher">
    <div>
      <h2 translate>Select Language</h2>
      <ul class="lang-selector">
        <li><a href="#" (click)="changeLanguage('1',$event)" [ngClass]="{'active': selectedLanuageId === '1'}">English</a></li>
        
        <li><a href="#" (click)="changeLanguage('2',$event)" [ngClass]="{'active': selectedLanuageId === '2'}"><bdi dir="ltr">中文（繁體）</bdi></a></li>

        <li><a href="#" (click)="changeLanguage('3',$event)" [ngClass]="{'active': selectedLanuageId === '3'}"><bdi dir="ltr">中文（简体）</bdi></a></li>

        <li><a href="#" (click)="changeLanguage('4',$event)" [ngClass]="{'active': selectedLanuageId === '4'}">العربية</a></li>
      </ul>
      <p class="close-lang-switcher mt-3"><fa-icon icon="close" (click)="closeModal()"></fa-icon></p> 
    </div>
  </div>
  <!-- end:desktop menu items -->
</div>