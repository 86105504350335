import { Component, OnInit } from '@angular/core';
import { register } from 'swiper/element/bundle';
import { TranslateService } from '@ngx-translate/core';
import { IpAddressService } from 'src/providers/ip-address.service';
import { GetDataService } from 'src/providers/get-data.service';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

register();
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  domain_name = environment.domain_name;

  constructor(private translateService : TranslateService,private ipService : IpAddressService,private getData : GetDataService,private cookieService: CookieService,private route: ActivatedRoute) {
    this.translateService.setDefaultLang('en');
    this.translateService.addLangs(['en', 'si', 'tr','ar']);
    let langId= localStorage.getItem('LanguageID')
    if(langId == null){
      localStorage.setItem('LanguageID', '1');
      this.changeLanguage('1')
    }else{
      this.changeLanguage(langId)
    }
    let isArabicValue = localStorage.getItem('isArabic')
    if(isArabicValue == null){
      localStorage.setItem('isArabic', 'no');
    }
  }

  async ngOnInit() {
    let locid = localStorage.getItem('LocationID');
    let rwc_usr_ip_source = this.cookieService.get('rwc_usr_ip_source');
    if(locid == null || !rwc_usr_ip_source) {
      await this.ipService.storeCookies();
    }
    await this.ipService.startMonitoring();
    this.route.queryParams.subscribe(params => {
      // Extract the first key from queryParams, assuming the locale is the first parameter
      const langKey = Object.keys(params)[0]; 
      if(langKey){
        this.changeLangueageFromURL(langKey);
      }
    })
  }

  changeLanguage(id:any){
    if(id === '1'){
      let language = 'en';
      this.translateService.use(language);
    }else if(id === '2'){
      let language = 'tr';
      this.translateService.use(language);
    }else if(id === '3'){
      let language = 'si';
      this.translateService.use(language);
    }else{
      let language = 'ar';
      document.documentElement.dir = 'rtl';
      this.translateService.use(language);
    }
  }
  changeLangueageFromURL(langKey:any){
    let language = 'en';
    let langid = 1;
    let langCode = 'EN';
    switch (langKey) {
      case 'zh-HK':
        language = 'tr'; // Traditional Chinese (zh-HK)
        langid = 2;
        langCode = 'CN';
        break;
      case 'zh-CN':
        language = 'si'; // Simplified Chinese (zh-CN)
        langid = 3;
        langCode = 'CN';
        break;
      default:
        language = 'en'; // Fallback to English
        langid = 1;
        langCode = 'EN';
        break;
    }
    // Apply language
    localStorage.setItem('LanguageID', langid.toString());
    localStorage.setItem('Language', 'EN');
    this.cookieService.set('rwc_site_lang', langCode, 1, '/', this.domain_name);
    this.cookieService.set('rwc_site_lang_variant',langCode, 1, '/', this.domain_name);
    this.translateService.use(language);
  }
}
