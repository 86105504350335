import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IonLoaderService } from 'src/providers/ion-loader.service';
@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent  implements OnInit {
  constructor(private router: Router, private loader : IonLoaderService) { }

  ngOnInit(): void {
    this.loader.simpleLoader();
    const url = this.router.url;
    const baseURL = 'https://webpages.rwcruises.com';
    window.location.href = `${baseURL}${url}`;
  }
  ngOnDestroy(){
    this.loader.dismissLoader();
  }

}
