export const countryJson=[
    {
        "name": "Andorra",
        "code": "AD",
        "flag": "🇦🇩",
        "unicode": "U+1F1E6 U+1F1E9",
        "image": "AD.svg",
        "dial_code": "+376"
    },
    {
        "name": "United Arab Emirates",
        "code": "AE",
        "flag": "🇦🇪",
        "unicode": "U+1F1E6 U+1F1EA",
        "image": "AE.svg",
        "dial_code": "+971"
    },
    {
        "name": "Afghanistan",
        "code": "AF",
        "flag": "🇦🇫",
        "unicode": "U+1F1E6 U+1F1EB",
        "image": "AF.svg",
        "dial_code": "+93"
    },
    {
        "name": "Antigua & Barbuda",
        "code": "AG",
        "flag": "🇦🇬",
        "unicode": "U+1F1E6 U+1F1EC",
        "image": "AG.svg",
        "dial_code": "+1268"
    },
    {
        "name": "Anguilla",
        "code": "AI",
        "flag": "🇦🇮",
        "unicode": "U+1F1E6 U+1F1EE",
        "image": "AI.svg",
        "dial_code": "+1264"
    },
    {
        "name": "Albania",
        "code": "AL",
        "flag": "🇦🇱",
        "unicode": "U+1F1E6 U+1F1F1",
        "image": "AL.svg",
        "dial_code": "+355"
    },
    {
        "name": "Armenia",
        "code": "AM",
        "flag": "🇦🇲",
        "unicode": "U+1F1E6 U+1F1F2",
        "image": "AM.svg",
        "dial_code": "+374"
    },
    {
        "name": "Angola",
        "code": "AO",
        "flag": "🇦🇴",
        "unicode": "U+1F1E6 U+1F1F4",
        "image": "AO.svg",
        "dial_code": "+244"
    },
    {
        "name": "Antarctica",
        "code": "AQ",
        "flag": "🇦🇶",
        "unicode": "U+1F1E6 U+1F1F6",
        "image": "AQ.svg",
        "dial_code": "+672"
    },
    {
        "name": "Argentina",
        "code": "AR",
        "flag": "🇦🇷",
        "unicode": "U+1F1E6 U+1F1F7",
        "image": "AR.svg",
        "dial_code": "+54"
    },
    {
        "name": "American Samoa",
        "code": "AS",
        "flag": "🇦🇸",
        "unicode": "U+1F1E6 U+1F1F8",
        "image": "AS.svg",
        "dial_code": "+1684"
    },
    {
        "name": "Austria",
        "code": "AT",
        "flag": "🇦🇹",
        "unicode": "U+1F1E6 U+1F1F9",
        "image": "AT.svg",
        "dial_code": "+43"
    },
    {
        "name": "Australia",
        "code": "AU",
        "flag": "🇦🇺",
        "unicode": "U+1F1E6 U+1F1FA",
        "image": "AU.svg",
        "dial_code": "+61"
    },
    {
        "name": "Aruba",
        "code": "AW",
        "flag": "🇦🇼",
        "unicode": "U+1F1E6 U+1F1FC",
        "image": "AW.svg",
        "dial_code": "+297"
    },
    {
        "name": "Åland Islands",
        "code": "AX",
        "flag": "🇦🇽",
        "unicode": "U+1F1E6 U+1F1FD",
        "image": "AX.svg",
        "dial_code": "+358"
    },
    {
        "name": "Azerbaijan",
        "code": "AZ",
        "flag": "🇦🇿",
        "unicode": "U+1F1E6 U+1F1FF",
        "image": "AZ.svg",
        "dial_code": "+994"
    },
    {
        "name": "Bosnia & Herzegovina",
        "code": "BA",
        "flag": "🇧🇦",
        "unicode": "U+1F1E7 U+1F1E6",
        "image": "BA.svg",
        "dial_code": "+387"
    },
    {
        "name": "Barbados",
        "code": "BB",
        "flag": "🇧🇧",
        "unicode": "U+1F1E7 U+1F1E7",
        "image": "BB.svg",
        "dial_code": "+1246"
    },
    {
        "name": "Bangladesh",
        "code": "BD",
        "flag": "🇧🇩",
        "unicode": "U+1F1E7 U+1F1E9",
        "image": "BD.svg",
        "dial_code": "+880"
    },
    {
        "name": "Belgium",
        "code": "BE",
        "flag": "🇧🇪",
        "unicode": "U+1F1E7 U+1F1EA",
        "image": "BE.svg",
        "dial_code": "+32"
    },
    {
        "name": "Burkina Faso",
        "code": "BF",
        "flag": "🇧🇫",
        "unicode": "U+1F1E7 U+1F1EB",
        "image": "BF.svg",
        "dial_code": "+226"
    },
    {
        "name": "Bulgaria",
        "code": "BG",
        "flag": "🇧🇬",
        "unicode": "U+1F1E7 U+1F1EC",
        "image": "BG.svg",
        "dial_code": "+359"
    },
    {
        "name": "Bahrain",
        "code": "BH",
        "flag": "🇧🇭",
        "unicode": "U+1F1E7 U+1F1ED",
        "image": "BH.svg",
        "dial_code": "+973"
    },
    {
        "name": "Burundi",
        "code": "BI",
        "flag": "🇧🇮",
        "unicode": "U+1F1E7 U+1F1EE",
        "image": "BI.svg",
        "dial_code": "+257"
    },
    {
        "name": "Benin",
        "code": "BJ",
        "flag": "🇧🇯",
        "unicode": "U+1F1E7 U+1F1EF",
        "image": "BJ.svg",
        "dial_code": "+229"
    },
    {
        "name": "St. Barthélemy",
        "code": "BL",
        "flag": "🇧🇱",
        "unicode": "U+1F1E7 U+1F1F1",
        "image": "BL.svg",
        "dial_code": "+590"
    },
    {
        "name": "Bermuda",
        "code": "BM",
        "flag": "🇧🇲",
        "unicode": "U+1F1E7 U+1F1F2",
        "image": "BM.svg",
        "dial_code": "+1441"
    },
    {
        "name": "Brunei Darussalam",
        "code": "BN",
        "flag": "🇧🇳",
        "unicode": "U+1F1E7 U+1F1F3",
        "image": "BN.svg",
        "dial_code": "+673"
    },
    {
        "name": "Bolivia",
        "code": "BO",
        "flag": "🇧🇴",
        "unicode": "U+1F1E7 U+1F1F4",
        "image": "BO.svg",
        "dial_code": "+591"
    },
    {
        "name": "Brazil",
        "code": "BR",
        "flag": "🇧🇷",
        "unicode": "U+1F1E7 U+1F1F7",
        "image": "BR.svg",
        "dial_code": "+55"
    },
    {
        "name": "Bahamas",
        "code": "BS",
        "flag": "🇧🇸",
        "unicode": "U+1F1E7 U+1F1F8",
        "image": "BS.svg",
        "dial_code": "+1242"
    },
    {
        "name": "Bhutan",
        "code": "BT",
        "flag": "🇧🇹",
        "unicode": "U+1F1E7 U+1F1F9",
        "image": "BT.svg",
        "dial_code": "+975"
    },
    {
        "name": "Botswana",
        "code": "BW",
        "flag": "🇧🇼",
        "unicode": "U+1F1E7 U+1F1FC",
        "image": "BW.svg",
        "dial_code": "+267"
    },
    {
        "name": "Belarus",
        "code": "BY",
        "flag": "🇧🇾",
        "unicode": "U+1F1E7 U+1F1FE",
        "image": "BY.svg",
        "dial_code": "+375"
    },
    {
        "name": "Belize",
        "code": "BZ",
        "flag": "🇧🇿",
        "unicode": "U+1F1E7 U+1F1FF",
        "image": "BZ.svg",
        "dial_code": "+501"
    },
    {
        "name": "Canada",
        "code": "CA",
        "flag": "🇨🇦",
        "unicode": "U+1F1E8 U+1F1E6",
        "image": "CA.svg",
        "dial_code": "+1"
    },
    {
        "name": "Cocos (Keeling) Islands",
        "code": "CC",
        "flag": "🇨🇨",
        "unicode": "U+1F1E8 U+1F1E8",
        "image": "CC.svg",
        "dial_code": "+61"
    },
    {
        "name": "Congo - Kinshasa",
        "code": "CD",
        "flag": "🇨🇩",
        "unicode": "U+1F1E8 U+1F1E9",
        "image": "CD.svg",
        "dial_code": "+243"
    },
    {
        "name": "Central African Republic",
        "code": "CF",
        "flag": "🇨🇫",
        "unicode": "U+1F1E8 U+1F1EB",
        "image": "CF.svg",
        "dial_code": "+236"
    },
    {
        "name": "Congo - Brazzaville",
        "code": "CG",
        "flag": "🇨🇬",
        "unicode": "U+1F1E8 U+1F1EC",
        "image": "CG.svg",
        "dial_code": "+242"
    },
    {
        "name": "Switzerland",
        "code": "CH",
        "flag": "🇨🇭",
        "unicode": "U+1F1E8 U+1F1ED",
        "image": "CH.svg",
        "dial_code": "+41"
    },
    {
        "name": "Côte d’Ivoire",
        "code": "CI",
        "flag": "🇨🇮",
        "unicode": "U+1F1E8 U+1F1EE",
        "image": "CI.svg",
        "dial_code": "+225"
    },
    {
        "name": "Cook Islands",
        "code": "CK",
        "flag": "🇨🇰",
        "unicode": "U+1F1E8 U+1F1F0",
        "image": "CK.svg",
        "dial_code": "+682"
    },
    {
        "name": "Chile",
        "code": "CL",
        "flag": "🇨🇱",
        "unicode": "U+1F1E8 U+1F1F1",
        "image": "CL.svg",
        "dial_code": "+56"
    },
    {
        "name": "Cameroon",
        "code": "CM",
        "flag": "🇨🇲",
        "unicode": "U+1F1E8 U+1F1F2",
        "image": "CM.svg",
        "dial_code": "+237"
    },
    {
        "name": "China",
        "code": "CN",
        "flag": "🇨🇳",
        "unicode": "U+1F1E8 U+1F1F3",
        "image": "CN.svg",
        "dial_code": "+86"
    },
    {
        "name": "Colombia",
        "code": "CO",
        "flag": "🇨🇴",
        "unicode": "U+1F1E8 U+1F1F4",
        "image": "CO.svg",
        "dial_code": "+57"
    },
    {
        "name": "Costa Rica",
        "code": "CR",
        "flag": "🇨🇷",
        "unicode": "U+1F1E8 U+1F1F7",
        "image": "CR.svg",
        "dial_code": "+506"
    },
    {
        "name": "Cuba",
        "code": "CU",
        "flag": "🇨🇺",
        "unicode": "U+1F1E8 U+1F1FA",
        "image": "CU.svg",
        "dial_code": "+53"
    },
    {
        "name": "Cape Verde",
        "code": "CV",
        "flag": "🇨🇻",
        "unicode": "U+1F1E8 U+1F1FB",
        "image": "CV.svg",
        "dial_code": "+238"
    },
    {
        "name": "Christmas Island",
        "code": "CX",
        "flag": "🇨🇽",
        "unicode": "U+1F1E8 U+1F1FD",
        "image": "CX.svg",
        "dial_code": "+61"
    },
    {
        "name": "Cyprus",
        "code": "CY",
        "flag": "🇨🇾",
        "unicode": "U+1F1E8 U+1F1FE",
        "image": "CY.svg",
        "dial_code": "+357"
    },
    {
        "name": "Czechia",
        "code": "CZ",
        "flag": "🇨🇿",
        "unicode": "U+1F1E8 U+1F1FF",
        "image": "CZ.svg",
        "dial_code": "+420"
    },
    {
        "name": "Germany",
        "code": "DE",
        "flag": "🇩🇪",
        "unicode": "U+1F1E9 U+1F1EA",
        "image": "DE.svg",
        "dial_code": "+49"
    },
    {
        "name": "Djibouti",
        "code": "DJ",
        "flag": "🇩🇯",
        "unicode": "U+1F1E9 U+1F1EF",
        "image": "DJ.svg",
        "dial_code": "+253"
    },
    {
        "name": "Denmark",
        "code": "DK",
        "flag": "🇩🇰",
        "unicode": "U+1F1E9 U+1F1F0",
        "image": "DK.svg",
        "dial_code": "+45"
    },
    {
        "name": "Dominica",
        "code": "DM",
        "flag": "🇩🇲",
        "unicode": "U+1F1E9 U+1F1F2",
        "image": "DM.svg",
        "dial_code": "+1767"
    },
    {
        "name": "Dominican Republic",
        "code": "DO",
        "flag": "🇩🇴",
        "unicode": "U+1F1E9 U+1F1F4",
        "image": "DO.svg",
        "dial_code": "+1849"
    },
    {
        "name": "Algeria",
        "code": "DZ",
        "flag": "🇩🇿",
        "unicode": "U+1F1E9 U+1F1FF",
        "image": "DZ.svg",
        "dial_code": "+213"
    },
    {
        "name": "Ecuador",
        "code": "EC",
        "flag": "🇪🇨",
        "unicode": "U+1F1EA U+1F1E8",
        "image": "EC.svg",
        "dial_code": "+593"
    },
    {
        "name": "Estonia",
        "code": "EE",
        "flag": "🇪🇪",
        "unicode": "U+1F1EA U+1F1EA",
        "image": "EE.svg",
        "dial_code": "+372"
    },
    {
        "name": "Egypt",
        "code": "EG",
        "flag": "🇪🇬",
        "unicode": "U+1F1EA U+1F1EC",
        "image": "EG.svg",
        "dial_code": "+20"
    },
    {
        "name": "Eritrea",
        "code": "ER",
        "flag": "🇪🇷",
        "unicode": "U+1F1EA U+1F1F7",
        "image": "ER.svg",
        "dial_code": "+291"
    },
    {
        "name": "Spain",
        "code": "ES",
        "flag": "🇪🇸",
        "unicode": "U+1F1EA U+1F1F8",
        "image": "ES.svg",
        "dial_code": "+34"
    },
    {
        "name": "Ethiopia",
        "code": "ET",
        "flag": "🇪🇹",
        "unicode": "U+1F1EA U+1F1F9",
        "image": "ET.svg",
        "dial_code": "+251"
    },
    {
        "name": "Finland",
        "code": "FI",
        "flag": "🇫🇮",
        "unicode": "U+1F1EB U+1F1EE",
        "image": "FI.svg",
        "dial_code": "+358"
    },
    {
        "name": "Fiji",
        "code": "FJ",
        "flag": "🇫🇯",
        "unicode": "U+1F1EB U+1F1EF",
        "image": "FJ.svg",
        "dial_code": "+679"
    },
    {
        "name": "Falkland Islands",
        "code": "FK",
        "flag": "🇫🇰",
        "unicode": "U+1F1EB U+1F1F0",
        "image": "FK.svg",
        "dial_code": "+500"
    },
    {
        "name": "Micronesia",
        "code": "FM",
        "flag": "🇫🇲",
        "unicode": "U+1F1EB U+1F1F2",
        "image": "FM.svg",
        "dial_code": "+691"
    },
    {
        "name": "Faroe Islands",
        "code": "FO",
        "flag": "🇫🇴",
        "unicode": "U+1F1EB U+1F1F4",
        "image": "FO.svg",
        "dial_code": "+298"
    },
    {
        "name": "France",
        "code": "FR",
        "flag": "🇫🇷",
        "unicode": "U+1F1EB U+1F1F7",
        "image": "FR.svg",
        "dial_code": "+33"
    },
    {
        "name": "Gabon",
        "code": "GA",
        "flag": "🇬🇦",
        "unicode": "U+1F1EC U+1F1E6",
        "image": "GA.svg",
        "dial_code": "+241"
    },
    {
        "name": "United Kingdom",
        "code": "GB",
        "flag": "🇬🇧",
        "unicode": "U+1F1EC U+1F1E7",
        "image": "GB.svg",
        "dial_code": "+44"
    },
    {
        "name": "Grenada",
        "code": "GD",
        "flag": "🇬🇩",
        "unicode": "U+1F1EC U+1F1E9",
        "image": "GD.svg",
        "dial_code": "+1473"
    },
    {
        "name": "Georgia",
        "code": "GE",
        "flag": "🇬🇪",
        "unicode": "U+1F1EC U+1F1EA",
        "image": "GE.svg",
        "dial_code": "+995"
    },
    {
        "name": "French Guiana",
        "code": "GF",
        "flag": "🇬🇫",
        "unicode": "U+1F1EC U+1F1EB",
        "image": "GF.svg",
        "dial_code": "+594"
    },
    {
        "name": "Guernsey",
        "code": "GG",
        "flag": "🇬🇬",
        "unicode": "U+1F1EC U+1F1EC",
        "image": "GG.svg",
        "dial_code": "+44"
    },
    {
        "name": "Ghana",
        "code": "GH",
        "flag": "🇬🇭",
        "unicode": "U+1F1EC U+1F1ED",
        "image": "GH.svg",
        "dial_code": "+233"
    },
    {
        "name": "Gibraltar",
        "code": "GI",
        "flag": "🇬🇮",
        "unicode": "U+1F1EC U+1F1EE",
        "image": "GI.svg",
        "dial_code": "+350"
    },
    {
        "name": "Greenland",
        "code": "GL",
        "flag": "🇬🇱",
        "unicode": "U+1F1EC U+1F1F1",
        "image": "GL.svg",
        "dial_code": "+299"
    },
    {
        "name": "Gambia",
        "code": "GM",
        "flag": "🇬🇲",
        "unicode": "U+1F1EC U+1F1F2",
        "image": "GM.svg",
        "dial_code": "+220"
    },
    {
        "name": "Guinea",
        "code": "GN",
        "flag": "🇬🇳",
        "unicode": "U+1F1EC U+1F1F3",
        "image": "GN.svg",
        "dial_code": "+224"
    },
    {
        "name": "Guadeloupe",
        "code": "GP",
        "flag": "🇬🇵",
        "unicode": "U+1F1EC U+1F1F5",
        "image": "GP.svg",
        "dial_code": "+590"
    },
    {
        "name": "Equatorial Guinea",
        "code": "GQ",
        "flag": "🇬🇶",
        "unicode": "U+1F1EC U+1F1F6",
        "image": "GQ.svg",
        "dial_code": "+240"
    },
    {
        "name": "Greece",
        "code": "GR",
        "flag": "🇬🇷",
        "unicode": "U+1F1EC U+1F1F7",
        "image": "GR.svg",
        "dial_code": "+30"
    },
    {
        "name": "South Georgia & South Sandwich Islands",
        "code": "GS",
        "flag": "🇬🇸",
        "unicode": "U+1F1EC U+1F1F8",
        "image": "GS.svg",
        "dial_code": "+500"
    },
    {
        "name": "Guatemala",
        "code": "GT",
        "flag": "🇬🇹",
        "unicode": "U+1F1EC U+1F1F9",
        "image": "GT.svg",
        "dial_code": "+502"
    },
    {
        "name": "Guam",
        "code": "GU",
        "flag": "🇬🇺",
        "unicode": "U+1F1EC U+1F1FA",
        "image": "GU.svg",
        "dial_code": "+1671"
    },
    {
        "name": "Guinea-Bissau",
        "code": "GW",
        "flag": "🇬🇼",
        "unicode": "U+1F1EC U+1F1FC",
        "image": "GW.svg",
        "dial_code": "+245"
    },
    {
        "name": "Guyana",
        "code": "GY",
        "flag": "🇬🇾",
        "unicode": "U+1F1EC U+1F1FE",
        "image": "GY.svg",
        "dial_code": "+595"
    },
    {
        "name": "Hong Kong",
        "code": "HK",
        "flag": "🇭🇰",
        "unicode": "U+1F1ED U+1F1F0",
        "image": "HK.svg",
        "dial_code": "+852"
    },
    {
        "name": "Honduras",
        "code": "HN",
        "flag": "🇭🇳",
        "unicode": "U+1F1ED U+1F1F3",
        "image": "HN.svg",
        "dial_code": "+504"
    },
    {
        "name": "Croatia",
        "code": "HR",
        "flag": "🇭🇷",
        "unicode": "U+1F1ED U+1F1F7",
        "image": "HR.svg",
        "dial_code": "+385"
    },
    {
        "name": "Haiti",
        "code": "HT",
        "flag": "🇭🇹",
        "unicode": "U+1F1ED U+1F1F9",
        "image": "HT.svg",
        "dial_code": "+509"
    },
    {
        "name": "Hungary",
        "code": "HU",
        "flag": "🇭🇺",
        "unicode": "U+1F1ED U+1F1FA",
        "image": "HU.svg",
        "dial_code": "+36"
    },
    {
        "name": "Indonesia",
        "code": "ID",
        "flag": "🇮🇩",
        "unicode": "U+1F1EE U+1F1E9",
        "image": "ID.svg",
        "dial_code": "+62"
    },
    {
        "name": "Ireland",
        "code": "IE",
        "flag": "🇮🇪",
        "unicode": "U+1F1EE U+1F1EA",
        "image": "IE.svg",
        "dial_code": "+353"
    },
    {
        "name": "Israel",
        "code": "IL",
        "flag": "🇮🇱",
        "unicode": "U+1F1EE U+1F1F1",
        "image": "IL.svg",
        "dial_code": "+972"
    },
    {
        "name": "Isle of Man",
        "code": "IM",
        "flag": "🇮🇲",
        "unicode": "U+1F1EE U+1F1F2",
        "image": "IM.svg",
        "dial_code": "+44"
    },
    {
        "name": "India",
        "code": "IN",
        "flag": "🇮🇳",
        "unicode": "U+1F1EE U+1F1F3",
        "image": "IN.svg",
        "dial_code": "+91"
    },
    {
        "name": "British Indian Ocean Territory",
        "code": "IO",
        "flag": "🇮🇴",
        "unicode": "U+1F1EE U+1F1F4",
        "image": "IO.svg",
        "dial_code": "+246"
    },
    {
        "name": "Iraq",
        "code": "IQ",
        "flag": "🇮🇶",
        "unicode": "U+1F1EE U+1F1F6",
        "image": "IQ.svg",
        "dial_code": "+964"
    },
    {
        "name": "Iran",
        "code": "IR",
        "flag": "🇮🇷",
        "unicode": "U+1F1EE U+1F1F7",
        "image": "IR.svg",
        "dial_code": "+98"
    },
    {
        "name": "Iceland",
        "code": "IS",
        "flag": "🇮🇸",
        "unicode": "U+1F1EE U+1F1F8",
        "image": "IS.svg",
        "dial_code": "+354"
    },
    {
        "name": "Italy",
        "code": "IT",
        "flag": "🇮🇹",
        "unicode": "U+1F1EE U+1F1F9",
        "image": "IT.svg",
        "dial_code": "+39"
    },
    {
        "name": "Jersey",
        "code": "JE",
        "flag": "🇯🇪",
        "unicode": "U+1F1EF U+1F1EA",
        "image": "JE.svg",
        "dial_code": "+44"
    },
    {
        "name": "Jamaica",
        "code": "JM",
        "flag": "🇯🇲",
        "unicode": "U+1F1EF U+1F1F2",
        "image": "JM.svg",
        "dial_code": "+1876"
    },
    {
        "name": "Jordan",
        "code": "JO",
        "flag": "🇯🇴",
        "unicode": "U+1F1EF U+1F1F4",
        "image": "JO.svg",
        "dial_code": "+962"
    },
    {
        "name": "Japan",
        "code": "JP",
        "flag": "🇯🇵",
        "unicode": "U+1F1EF U+1F1F5",
        "image": "JP.svg",
        "dial_code": "+81"
    },
    {
        "name": "Kenya",
        "code": "KE",
        "flag": "🇰🇪",
        "unicode": "U+1F1F0 U+1F1EA",
        "image": "KE.svg",
        "dial_code": "+254"
    },
    {
        "name": "Kyrgyzstan",
        "code": "KG",
        "flag": "🇰🇬",
        "unicode": "U+1F1F0 U+1F1EC",
        "image": "KG.svg",
        "dial_code": "+996"
    },
    {
        "name": "Cambodia",
        "code": "KH",
        "flag": "🇰🇭",
        "unicode": "U+1F1F0 U+1F1ED",
        "image": "KH.svg",
        "dial_code": "+855"
    },
    {
        "name": "Kiribati",
        "code": "KI",
        "flag": "🇰🇮",
        "unicode": "U+1F1F0 U+1F1EE",
        "image": "KI.svg",
        "dial_code": "+686"
    },
    {
        "name": "Comoros",
        "code": "KM",
        "flag": "🇰🇲",
        "unicode": "U+1F1F0 U+1F1F2",
        "image": "KM.svg",
        "dial_code": "+269"
    },
    {
        "name": "St. Kitts & Nevis",
        "code": "KN",
        "flag": "🇰🇳",
        "unicode": "U+1F1F0 U+1F1F3",
        "image": "KN.svg",
        "dial_code": "+1869"
    },
    {
        "name": "North Korea",
        "code": "KP",
        "flag": "🇰🇵",
        "unicode": "U+1F1F0 U+1F1F5",
        "image": "KP.svg",
        "dial_code": "+850"
    },
    {
        "name": "Korea, Democratic People's Republic of",
        "code": "KR",
        "flag": "🇰🇷",
        "unicode": "U+1F1F0 U+1F1F7",
        "image": "KR.svg",
        "dial_code": "+82"
    },
    {
        "name": "Kuwait",
        "code": "KW",
        "flag": "🇰🇼",
        "unicode": "U+1F1F0 U+1F1FC",
        "image": "KW.svg",
        "dial_code": "+965"
    },
    {
        "name": "Cayman Islands",
        "code": "KY",
        "flag": "🇰🇾",
        "unicode": "U+1F1F0 U+1F1FE",
        "image": "KY.svg",
        "dial_code": "+ 345"
    },
    {
        "name": "Kazakhstan",
        "code": "KZ",
        "flag": "🇰🇿",
        "unicode": "U+1F1F0 U+1F1FF",
        "image": "KZ.svg",
        "dial_code": "+77"
    },
    {
        "name": "Lao People's Democratic Republic",
        "code": "LA",
        "flag": "🇱🇦",
        "unicode": "U+1F1F1 U+1F1E6",
        "image": "LA.svg",
        "dial_code": "+856"
    },
    {
        "name": "Lebanon",
        "code": "LB",
        "flag": "🇱🇧",
        "unicode": "U+1F1F1 U+1F1E7",
        "image": "LB.svg",
        "dial_code": "+961"
    },
    {
        "name": "St. Lucia",
        "code": "LC",
        "flag": "🇱🇨",
        "unicode": "U+1F1F1 U+1F1E8",
        "image": "LC.svg",
        "dial_code": "+1758"
    },
    {
        "name": "Liechtenstein",
        "code": "LI",
        "flag": "🇱🇮",
        "unicode": "U+1F1F1 U+1F1EE",
        "image": "LI.svg",
        "dial_code": "+423"
    },
    {
        "name": "Sri Lanka",
        "code": "LK",
        "flag": "🇱🇰",
        "unicode": "U+1F1F1 U+1F1F0",
        "image": "LK.svg",
        "dial_code": "+94"
    },
    {
        "name": "Liberia",
        "code": "LR",
        "flag": "🇱🇷",
        "unicode": "U+1F1F1 U+1F1F7",
        "image": "LR.svg",
        "dial_code": "+231"
    },
    {
        "name": "Lesotho",
        "code": "LS",
        "flag": "🇱🇸",
        "unicode": "U+1F1F1 U+1F1F8",
        "image": "LS.svg",
        "dial_code": "+266"
    },
    {
        "name": "Lithuania",
        "code": "LT",
        "flag": "🇱🇹",
        "unicode": "U+1F1F1 U+1F1F9",
        "image": "LT.svg",
        "dial_code": "+370"
    },
    {
        "name": "Luxembourg",
        "code": "LU",
        "flag": "🇱🇺",
        "unicode": "U+1F1F1 U+1F1FA",
        "image": "LU.svg",
        "dial_code": "+352"
    },
    {
        "name": "Latvia",
        "code": "LV",
        "flag": "🇱🇻",
        "unicode": "U+1F1F1 U+1F1FB",
        "image": "LV.svg",
        "dial_code": "+371"
    },
    {
        "name": "Libya",
        "code": "LY",
        "flag": "🇱🇾",
        "unicode": "U+1F1F1 U+1F1FE",
        "image": "LY.svg",
        "dial_code": "+218"
    },
    {
        "name": "Morocco",
        "code": "MA",
        "flag": "🇲🇦",
        "unicode": "U+1F1F2 U+1F1E6",
        "image": "MA.svg",
        "dial_code": "+212"
    },
    {
        "name": "Monaco",
        "code": "MC",
        "flag": "🇲🇨",
        "unicode": "U+1F1F2 U+1F1E8",
        "image": "MC.svg",
        "dial_code": "+377"
    },
    {
        "name": "Moldova",
        "code": "MD",
        "flag": "🇲🇩",
        "unicode": "U+1F1F2 U+1F1E9",
        "image": "MD.svg",
        "dial_code": "+373"
    },
    {
        "name": "Montenegro",
        "code": "ME",
        "flag": "🇲🇪",
        "unicode": "U+1F1F2 U+1F1EA",
        "image": "ME.svg",
        "dial_code": "+382"
    },
    {
        "name": "St. Martin",
        "code": "MF",
        "flag": "🇲🇫",
        "unicode": "U+1F1F2 U+1F1EB",
        "image": "MF.svg",
        "dial_code": "+590"
    },
    {
        "name": "Madagascar",
        "code": "MG",
        "flag": "🇲🇬",
        "unicode": "U+1F1F2 U+1F1EC",
        "image": "MG.svg",
        "dial_code": "+261"
    },
    {
        "name": "Marshall Islands",
        "code": "MH",
        "flag": "🇲🇭",
        "unicode": "U+1F1F2 U+1F1ED",
        "image": "MH.svg",
        "dial_code": "+692"
    },
    {
        "name": "North Macedonia",
        "code": "MK",
        "flag": "🇲🇰",
        "unicode": "U+1F1F2 U+1F1F0",
        "image": "MK.svg",
        "dial_code": "+389"
    },
    {
        "name": "Mali",
        "code": "ML",
        "flag": "🇲🇱",
        "unicode": "U+1F1F2 U+1F1F1",
        "image": "ML.svg",
        "dial_code": "+223"
    },
    {
        "name": "Myanmar",
        "code": "MM",
        "flag": "🇲🇲",
        "unicode": "U+1F1F2 U+1F1F2",
        "image": "MM.svg",
        "dial_code": "+95"
    },
    {
        "name": "Mongolia",
        "code": "MN",
        "flag": "🇲🇳",
        "unicode": "U+1F1F2 U+1F1F3",
        "image": "MN.svg",
        "dial_code": "+976"
    },
    {
        "name": "Macao SAR China",
        "code": "MO",
        "flag": "🇲🇴",
        "unicode": "U+1F1F2 U+1F1F4",
        "image": "MO.svg",
        "dial_code": "+853"
    },
    {
        "name": "Northern Mariana Islands",
        "code": "MP",
        "flag": "🇲🇵",
        "unicode": "U+1F1F2 U+1F1F5",
        "image": "MP.svg",
        "dial_code": "+1670"
    },
    {
        "name": "Martinique",
        "code": "MQ",
        "flag": "🇲🇶",
        "unicode": "U+1F1F2 U+1F1F6",
        "image": "MQ.svg",
        "dial_code": "+596"
    },
    {
        "name": "Mauritania",
        "code": "MR",
        "flag": "🇲🇷",
        "unicode": "U+1F1F2 U+1F1F7",
        "image": "MR.svg",
        "dial_code": "+222"
    },
    {
        "name": "Montserrat",
        "code": "MS",
        "flag": "🇲🇸",
        "unicode": "U+1F1F2 U+1F1F8",
        "image": "MS.svg",
        "dial_code": "+1664"
    },
    {
        "name": "Malta",
        "code": "MT",
        "flag": "🇲🇹",
        "unicode": "U+1F1F2 U+1F1F9",
        "image": "MT.svg",
        "dial_code": "+356"
    },
    {
        "name": "Mauritius",
        "code": "MU",
        "flag": "🇲🇺",
        "unicode": "U+1F1F2 U+1F1FA",
        "image": "MU.svg",
        "dial_code": "+230"
    },
    {
        "name": "Maldives",
        "code": "MV",
        "flag": "🇲🇻",
        "unicode": "U+1F1F2 U+1F1FB",
        "image": "MV.svg",
        "dial_code": "+960"
    },
    {
        "name": "Malawi",
        "code": "MW",
        "flag": "🇲🇼",
        "unicode": "U+1F1F2 U+1F1FC",
        "image": "MW.svg",
        "dial_code": "+265"
    },
    {
        "name": "Mexico",
        "code": "MX",
        "flag": "🇲🇽",
        "unicode": "U+1F1F2 U+1F1FD",
        "image": "MX.svg",
        "dial_code": "+52"
    },
    {
        "name": "Malaysia",
        "code": "MY",
        "flag": "🇲🇾",
        "unicode": "U+1F1F2 U+1F1FE",
        "image": "MY.svg",
        "dial_code": "+60"
    },
    {
        "name": "Mozambique",
        "code": "MZ",
        "flag": "🇲🇿",
        "unicode": "U+1F1F2 U+1F1FF",
        "image": "MZ.svg",
        "dial_code": "+258"
    },
    {
        "name": "Namibia",
        "code": "NA",
        "flag": "🇳🇦",
        "unicode": "U+1F1F3 U+1F1E6",
        "image": "NA.svg",
        "dial_code": "+264"
    },
    {
        "name": "New Caledonia",
        "code": "NC",
        "flag": "🇳🇨",
        "unicode": "U+1F1F3 U+1F1E8",
        "image": "NC.svg",
        "dial_code": "+687"
    },
    {
        "name": "Niger",
        "code": "NE",
        "flag": "🇳🇪",
        "unicode": "U+1F1F3 U+1F1EA",
        "image": "NE.svg",
        "dial_code": "+227"
    },
    {
        "name": "Norfolk Island",
        "code": "NF",
        "flag": "🇳🇫",
        "unicode": "U+1F1F3 U+1F1EB",
        "image": "NF.svg",
        "dial_code": "+672"
    },
    {
        "name": "Nigeria",
        "code": "NG",
        "flag": "🇳🇬",
        "unicode": "U+1F1F3 U+1F1EC",
        "image": "NG.svg",
        "dial_code": "+234"
    },
    {
        "name": "Nicaragua",
        "code": "NI",
        "flag": "🇳🇮",
        "unicode": "U+1F1F3 U+1F1EE",
        "image": "NI.svg",
        "dial_code": "+505"
    },
    {
        "name": "Netherlands",
        "code": "NL",
        "flag": "🇳🇱",
        "unicode": "U+1F1F3 U+1F1F1",
        "image": "NL.svg",
        "dial_code": "+31"
    },
    {
        "name": "Norway",
        "code": "NO",
        "flag": "🇳🇴",
        "unicode": "U+1F1F3 U+1F1F4",
        "image": "NO.svg",
        "dial_code": "+47"
    },
    {
        "name": "Nepal",
        "code": "NP",
        "flag": "🇳🇵",
        "unicode": "U+1F1F3 U+1F1F5",
        "image": "NP.svg",
        "dial_code": "+977"
    },
    {
        "name": "Nauru",
        "code": "NR",
        "flag": "🇳🇷",
        "unicode": "U+1F1F3 U+1F1F7",
        "image": "NR.svg",
        "dial_code": "+674"
    },
    {
        "name": "Niue",
        "code": "NU",
        "flag": "🇳🇺",
        "unicode": "U+1F1F3 U+1F1FA",
        "image": "NU.svg",
        "dial_code": "+683"
    },
    {
        "name": "New Zealand",
        "code": "NZ",
        "flag": "🇳🇿",
        "unicode": "U+1F1F3 U+1F1FF",
        "image": "NZ.svg",
        "dial_code": "+64"
    },
    {
        "name": "Oman",
        "code": "OM",
        "flag": "🇴🇲",
        "unicode": "U+1F1F4 U+1F1F2",
        "image": "OM.svg",
        "dial_code": "+968"
    },
    {
        "name": "Panama",
        "code": "PA",
        "flag": "🇵🇦",
        "unicode": "U+1F1F5 U+1F1E6",
        "image": "PA.svg",
        "dial_code": "+507"
    },
    {
        "name": "Peru",
        "code": "PE",
        "flag": "🇵🇪",
        "unicode": "U+1F1F5 U+1F1EA",
        "image": "PE.svg",
        "dial_code": "+51"
    },
    {
        "name": "French Polynesia",
        "code": "PF",
        "flag": "🇵🇫",
        "unicode": "U+1F1F5 U+1F1EB",
        "image": "PF.svg",
        "dial_code": "+689"
    },
    {
        "name": "Papua New Guinea",
        "code": "PG",
        "flag": "🇵🇬",
        "unicode": "U+1F1F5 U+1F1EC",
        "image": "PG.svg",
        "dial_code": "+675"
    },
    {
        "name": "Philippines",
        "code": "PH",
        "flag": "🇵🇭",
        "unicode": "U+1F1F5 U+1F1ED",
        "image": "PH.svg",
        "dial_code": "+63"
    },
    {
        "name": "Pakistan",
        "code": "PK",
        "flag": "🇵🇰",
        "unicode": "U+1F1F5 U+1F1F0",
        "image": "PK.svg",
        "dial_code": "+92"
    },
    {
        "name": "Poland",
        "code": "PL",
        "flag": "🇵🇱",
        "unicode": "U+1F1F5 U+1F1F1",
        "image": "PL.svg",
        "dial_code": "+48"
    },
    {
        "name": "St. Pierre & Miquelon",
        "code": "PM",
        "flag": "🇵🇲",
        "unicode": "U+1F1F5 U+1F1F2",
        "image": "PM.svg",
        "dial_code": "+508"
    },
    {
        "name": "Pitcairn Islands",
        "code": "PN",
        "flag": "🇵🇳",
        "unicode": "U+1F1F5 U+1F1F3",
        "image": "PN.svg",
        "dial_code": "+872"
    },
    {
        "name": "Puerto Rico",
        "code": "PR",
        "flag": "🇵🇷",
        "unicode": "U+1F1F5 U+1F1F7",
        "image": "PR.svg",
        "dial_code": "+1939"
    },
    {
        "name": "Palestinian Territories",
        "code": "PS",
        "flag": "🇵🇸",
        "unicode": "U+1F1F5 U+1F1F8",
        "image": "PS.svg",
        "dial_code": "+970"
    },
    {
        "name": "Portugal",
        "code": "PT",
        "flag": "🇵🇹",
        "unicode": "U+1F1F5 U+1F1F9",
        "image": "PT.svg",
        "dial_code": "+351"
    },
    {
        "name": "Palau",
        "code": "PW",
        "flag": "🇵🇼",
        "unicode": "U+1F1F5 U+1F1FC",
        "image": "PW.svg",
        "dial_code": "+680"
    },
    {
        "name": "Paraguay",
        "code": "PY",
        "flag": "🇵🇾",
        "unicode": "U+1F1F5 U+1F1FE",
        "image": "PY.svg",
        "dial_code": "+595"
    },
    {
        "name": "Qatar",
        "code": "QA",
        "flag": "🇶🇦",
        "unicode": "U+1F1F6 U+1F1E6",
        "image": "QA.svg",
        "dial_code": "+974"
    },
    {
        "name": "Réunion",
        "code": "RE",
        "flag": "🇷🇪",
        "unicode": "U+1F1F7 U+1F1EA",
        "image": "RE.svg",
        "dial_code": "+262"
    },
    {
        "name": "Romania",
        "code": "RO",
        "flag": "🇷🇴",
        "unicode": "U+1F1F7 U+1F1F4",
        "image": "RO.svg",
        "dial_code": "+40"
    },
    {
        "name": "Serbia",
        "code": "RS",
        "flag": "🇷🇸",
        "unicode": "U+1F1F7 U+1F1F8",
        "image": "RS.svg",
        "dial_code": "+381"
    },
    {
        "name": "Russia",
        "code": "RU",
        "flag": "🇷🇺",
        "unicode": "U+1F1F7 U+1F1FA",
        "image": "RU.svg",
        "dial_code": "+7"
    },
    {
        "name": "Rwanda",
        "code": "RW",
        "flag": "🇷🇼",
        "unicode": "U+1F1F7 U+1F1FC",
        "image": "RW.svg",
        "dial_code": "+250"
    },
    {
        "name": "Saudi Arabia",
        "code": "SA",
        "flag": "🇸🇦",
        "unicode": "U+1F1F8 U+1F1E6",
        "image": "SA.svg",
        "dial_code": "+966"
    },
    {
        "name": "Solomon Islands",
        "code": "SB",
        "flag": "🇸🇧",
        "unicode": "U+1F1F8 U+1F1E7",
        "image": "SB.svg",
        "dial_code": "+677"
    },
    {
        "name": "Seychelles",
        "code": "SC",
        "flag": "🇸🇨",
        "unicode": "U+1F1F8 U+1F1E8",
        "image": "SC.svg",
        "dial_code": "+248"
    },
    {
        "name": "Sudan",
        "code": "SD",
        "flag": "🇸🇩",
        "unicode": "U+1F1F8 U+1F1E9",
        "image": "SD.svg",
        "dial_code": "+249"
    },
    {
        "name": "Sweden",
        "code": "SE",
        "flag": "🇸🇪",
        "unicode": "U+1F1F8 U+1F1EA",
        "image": "SE.svg",
        "dial_code": "+46"
    },
    {
        "name": "Singapore",
        "code": "SG",
        "flag": "🇸🇬",
        "unicode": "U+1F1F8 U+1F1EC",
        "image": "SG.svg",
        "dial_code": "+65"
    },
    {
        "name": "St. Helena",
        "code": "SH",
        "flag": "🇸🇭",
        "unicode": "U+1F1F8 U+1F1ED",
        "image": "SH.svg",
        "dial_code": "+290"
    },
    {
        "name": "Slovenia",
        "code": "SI",
        "flag": "🇸🇮",
        "unicode": "U+1F1F8 U+1F1EE",
        "image": "SI.svg",
        "dial_code": "+386"
    },
    {
        "name": "Svalbard & Jan Mayen",
        "code": "SJ",
        "flag": "🇸🇯",
        "unicode": "U+1F1F8 U+1F1EF",
        "image": "SJ.svg",
        "dial_code": "+47"
    },
    {
        "name": "Slovakia",
        "code": "SK",
        "flag": "🇸🇰",
        "unicode": "U+1F1F8 U+1F1F0",
        "image": "SK.svg",
        "dial_code": "+421"
    },
    {
        "name": "Sierra Leone",
        "code": "SL",
        "flag": "🇸🇱",
        "unicode": "U+1F1F8 U+1F1F1",
        "image": "SL.svg",
        "dial_code": "+232"
    },
    {
        "name": "San Marino",
        "code": "SM",
        "flag": "🇸🇲",
        "unicode": "U+1F1F8 U+1F1F2",
        "image": "SM.svg",
        "dial_code": "+378"
    },
    {
        "name": "Senegal",
        "code": "SN",
        "flag": "🇸🇳",
        "unicode": "U+1F1F8 U+1F1F3",
        "image": "SN.svg",
        "dial_code": "+221"
    },
    {
        "name": "Somalia",
        "code": "SO",
        "flag": "🇸🇴",
        "unicode": "U+1F1F8 U+1F1F4",
        "image": "SO.svg",
        "dial_code": "+252"
    },
    {
        "name": "Suriname",
        "code": "SR",
        "flag": "🇸🇷",
        "unicode": "U+1F1F8 U+1F1F7",
        "image": "SR.svg",
        "dial_code": "+597"
    },
    {
        "name": "South Sudan",
        "code": "SS",
        "flag": "🇸🇸",
        "unicode": "U+1F1F8 U+1F1F8",
        "image": "SS.svg",
        "dial_code": "+211"
    },
    {
        "name": "São Tomé & Príncipe",
        "code": "ST",
        "flag": "🇸🇹",
        "unicode": "U+1F1F8 U+1F1F9",
        "image": "ST.svg",
        "dial_code": "+239"
    },
    {
        "name": "El Salvador",
        "code": "SV",
        "flag": "🇸🇻",
        "unicode": "U+1F1F8 U+1F1FB",
        "image": "SV.svg",
        "dial_code": "+503"
    },
    {
        "name": "Syria",
        "code": "SY",
        "flag": "🇸🇾",
        "unicode": "U+1F1F8 U+1F1FE",
        "image": "SY.svg",
        "dial_code": "+963"
    },
    {
        "name": "Eswatini",
        "code": "SZ",
        "flag": "🇸🇿",
        "unicode": "U+1F1F8 U+1F1FF",
        "image": "SZ.svg",
        "dial_code": "+268"
    },
    {
        "name": "Turks & Caicos Islands",
        "code": "TC",
        "flag": "🇹🇨",
        "unicode": "U+1F1F9 U+1F1E8",
        "image": "TC.svg",
        "dial_code": "+1649"
    },
    {
        "name": "Chad",
        "code": "TD",
        "flag": "🇹🇩",
        "unicode": "U+1F1F9 U+1F1E9",
        "image": "TD.svg",
        "dial_code": "+235"
    },
    {
        "name": "Togo",
        "code": "TG",
        "flag": "🇹🇬",
        "unicode": "U+1F1F9 U+1F1EC",
        "image": "TG.svg",
        "dial_code": "+228"
    },
    {
        "name": "Thailand",
        "code": "TH",
        "flag": "🇹🇭",
        "unicode": "U+1F1F9 U+1F1ED",
        "image": "TH.svg",
        "dial_code": "+66"
    },
    {
        "name": "Tajikistan",
        "code": "TJ",
        "flag": "🇹🇯",
        "unicode": "U+1F1F9 U+1F1EF",
        "image": "TJ.svg",
        "dial_code": "+992"
    },
    {
        "name": "Tokelau",
        "code": "TK",
        "flag": "🇹🇰",
        "unicode": "U+1F1F9 U+1F1F0",
        "image": "TK.svg",
        "dial_code": "+690"
    },
    {
        "name": "Timor-Leste",
        "code": "TL",
        "flag": "🇹🇱",
        "unicode": "U+1F1F9 U+1F1F1",
        "image": "TL.svg",
        "dial_code": "+670"
    },
    {
        "name": "Turkmenistan",
        "code": "TM",
        "flag": "🇹🇲",
        "unicode": "U+1F1F9 U+1F1F2",
        "image": "TM.svg",
        "dial_code": "+993"
    },
    {
        "name": "Tunisia",
        "code": "TN",
        "flag": "🇹🇳",
        "unicode": "U+1F1F9 U+1F1F3",
        "image": "TN.svg",
        "dial_code": "+216"
    },
    {
        "name": "Tonga",
        "code": "TO",
        "flag": "🇹🇴",
        "unicode": "U+1F1F9 U+1F1F4",
        "image": "TO.svg",
        "dial_code": "+676"
    },
    {
        "name": "Turkey",
        "code": "TR",
        "flag": "🇹🇷",
        "unicode": "U+1F1F9 U+1F1F7",
        "image": "TR.svg",
        "dial_code": "+90"
    },
    {
        "name": "Trinidad & Tobago",
        "code": "TT",
        "flag": "🇹🇹",
        "unicode": "U+1F1F9 U+1F1F9",
        "image": "TT.svg",
        "dial_code": "+1868"
    },
    {
        "name": "Tuvalu",
        "code": "TV",
        "flag": "🇹🇻",
        "unicode": "U+1F1F9 U+1F1FB",
        "image": "TV.svg",
        "dial_code": "+688"
    },
    {
        "name": "Taiwan",
        "code": "TW",
        "flag": "🇹🇼",
        "unicode": "U+1F1F9 U+1F1FC",
        "image": "TW.svg",
        "dial_code": "+886"
    },
    {
        "name": "Tanzania",
        "code": "TZ",
        "flag": "🇹🇿",
        "unicode": "U+1F1F9 U+1F1FF",
        "image": "TZ.svg",
        "dial_code": "+255"
    },
    {
        "name": "Ukraine",
        "code": "UA",
        "flag": "🇺🇦",
        "unicode": "U+1F1FA U+1F1E6",
        "image": "UA.svg",
        "dial_code": "+380"
    },
    {
        "name": "Uganda",
        "code": "UG",
        "flag": "🇺🇬",
        "unicode": "U+1F1FA U+1F1EC",
        "image": "UG.svg",
        "dial_code": "+256"
    },
    {
        "name": "United States",
        "code": "US",
        "flag": "🇺🇸",
        "unicode": "U+1F1FA U+1F1F8",
        "image": "US.svg",
        "dial_code": "+1"
    },
    {
        "name": "Uruguay",
        "code": "UY",
        "flag": "🇺🇾",
        "unicode": "U+1F1FA U+1F1FE",
        "image": "UY.svg",
        "dial_code": "+598"
    },
    {
        "name": "Uzbekistan",
        "code": "UZ",
        "flag": "🇺🇿",
        "unicode": "U+1F1FA U+1F1FF",
        "image": "UZ.svg",
        "dial_code": "+998"
    },
    {
        "name": "Vatican City",
        "code": "VA",
        "flag": "🇻🇦",
        "unicode": "U+1F1FB U+1F1E6",
        "image": "VA.svg",
        "dial_code": "+379"
    },
    {
        "name": "St. Vincent & Grenadines",
        "code": "VC",
        "flag": "🇻🇨",
        "unicode": "U+1F1FB U+1F1E8",
        "image": "VC.svg",
        "dial_code": "+1784"
    },
    {
        "name": "Venezuela",
        "code": "VE",
        "flag": "🇻🇪",
        "unicode": "U+1F1FB U+1F1EA",
        "image": "VE.svg",
        "dial_code": "+58"
    },
    {
        "name": "British Virgin Islands",
        "code": "VG",
        "flag": "🇻🇬",
        "unicode": "U+1F1FB U+1F1EC",
        "image": "VG.svg",
        "dial_code": "+1284"
    },
    {
        "name": "U.S. Virgin Islands",
        "code": "VI",
        "flag": "🇻🇮",
        "unicode": "U+1F1FB U+1F1EE",
        "image": "VI.svg",
        "dial_code": "+1340"
    },
    {
        "name": "Vietnam",
        "code": "VN",
        "flag": "🇻🇳",
        "unicode": "U+1F1FB U+1F1F3",
        "image": "VN.svg",
        "dial_code": "+84"
    },
    {
        "name": "Vanuatu",
        "code": "VU",
        "flag": "🇻🇺",
        "unicode": "U+1F1FB U+1F1FA",
        "image": "VU.svg",
        "dial_code": "+678"
    },
    {
        "name": "Wallis & Futuna",
        "code": "WF",
        "flag": "🇼🇫",
        "unicode": "U+1F1FC U+1F1EB",
        "image": "WF.svg",
        "dial_code": "+681"
    },
    {
        "name": "Samoa",
        "code": "WS",
        "flag": "🇼🇸",
        "unicode": "U+1F1FC U+1F1F8",
        "image": "WS.svg",
        "dial_code": "+685"
    },
    {
        "name": "Yemen",
        "code": "YE",
        "flag": "🇾🇪",
        "unicode": "U+1F1FE U+1F1EA",
        "image": "YE.svg",
        "dial_code": "+967"
    },
    {
        "name": "Mayotte",
        "code": "YT",
        "flag": "🇾🇹",
        "unicode": "U+1F1FE U+1F1F9",
        "image": "YT.svg",
        "dial_code": "+262"
    },
    {
        "name": "South Africa",
        "code": "ZA",
        "flag": "🇿🇦",
        "unicode": "U+1F1FF U+1F1E6",
        "image": "ZA.svg",
        "dial_code": "+27"
    },
    {
        "name": "Zambia",
        "code": "ZM",
        "flag": "🇿🇲",
        "unicode": "U+1F1FF U+1F1F2",
        "image": "ZM.svg",
        "dial_code": "+260"
    },
    {
        "name": "Zimbabwe",
        "code": "ZW",
        "flag": "🇿🇼",
        "unicode": "U+1F1FF U+1F1FC",
        "image": "ZW.svg",
        "dial_code": "+263"
    }
]