<div class="container-fluid footer-bottom border-top">
  <div class="container my-3">
    <div class="row align-items-center">
      <div class="col-md-4 text-center" [ngClass]="{'text-md-start':LanguageId != '4', 'text-md-end':LanguageId == '4'}">
        <p translate>© 2024 Resorts World Cruises</p>
      </div>
      <div class="col-md-8 text-center mt-3 mt-md-0" [ngClass]="{'text-md-end':LanguageId != '4', 'text-md-start':LanguageId == '4'}">
        <ul>
          <li><a [href]="billsOfRight" (click)="handleClick($event, billsOfRight)" translate>Bill of Rights</a></li>
          <li><a [href]="privacyPolicy" (click)="handleClick($event, privacyPolicy)" translate>Privacy Policy</a></li>
          <li><a [href]="cookiePolicy" (click)="handleClick($event, cookiePolicy)" translate>Cookie Policy</a></li>
          <li><a [href]="termsOfUse" (click)="handleClick($event, termsOfUse)" translate>Terms of Usage</a></li>
          <li><a [href]="passageTerms" (click)="handleClick($event, passageTerms)" translate>Passage Terms</a></li>
          <li><a [href]="termsandconditions" (click)="handleClick($event, termsandconditions)" translate>Terms & Conditions</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>