<head>
  <title>Thank You</title>
  <!-- Event snippet for Lead conversion page -->
  <script>
    gtag('event', 'conversion', { 'send_to': 'AW-16526537121/brIpCIX7tc0ZEKHju8g9' });
  </script>
  <!-- TikTok Pixel Code Start -->
  <script>
    !function (w, d, t) {
      w.TiktokAnalyticsObject = t; var ttq = w[t] = w[t] || []; ttq.methods = ["page", "track", "identify", "instances", "debug", "on", "off", "once", "ready", "alias", "group", "enableCookie", "disableCookie", "holdConsent", "revokeConsent", "grantConsent"], ttq.setAndDefer = function (t, e) { t[e] = function () { t.push([e].concat(Array.prototype.slice.call(arguments, 0))) } }; for (var i = 0; i < ttq.methods.length; i++)ttq.setAndDefer(ttq, ttq.methods[i]); ttq.instance = function (t) {
        for (
          var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)ttq.setAndDefer(e, ttq.methods[n]); return e
      }, ttq.load = function (e, n) {
        var r = "https://analytics.tiktok.com/i18n/pixel/events.js", o = n && n.partner; ttq._i = ttq._i || {}, ttq._i[e] = [], ttq._i[e]._u = r, ttq._t = ttq._t || {}, ttq._t[e] = +new Date, ttq._o = ttq._o || {}, ttq._o[e] = n || {}; n = document.createElement("script")
          ; n.type = "text/javascript", n.async = !0, n.src = r + "?sdkid=" + e + "&lib=" + t; e = document.getElementsByTagName("script")[0]; e.parentNode.insertBefore(n, e)
      };


      ttq.load('CR7IH5BC77U9GKN6L0V0');
      ttq.page();
    }(window, document, 'ttq');
  </script>
  <!-- TikTok Pixel Code End -->

  <!-- Meta Pixel Code -->
  <script>
    !function (f, b, e, v, n, t, s) {
      if (f.fbq) return; n = f.fbq = function () {
        n.callMethod ?
          n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      };
      if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
      n.queue = []; t = b.createElement(e); t.async = !0;
      t.src = v; s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s)
    }(window, document, 'script',
      'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '985986892858847');
    fbq('track', 'PageView');
  </script>
  <noscript><img height="1" width="1" class="no-display"
      src="https://www.facebook.com/tr?id=985986892858847&ev=PageView&noscript=1" /></noscript>
  <!-- End Meta Pixel Code -->
</head>
<button id="contact-button" class="contact-button" (click)="openPopup()">
  Click for Best Offer
</button>

<div #popupElement class="contact-section-popup" id="contact-popup">

  <!-- Contact -->
  <section class="contact-section max-container" id="booking-form">
    <div class="flex-container">
      <div class="image-container">
      </div>
      <div class="form-container">
        <div class="">
          <p class="title">Limited offer starting from <br> <span> USD 380* </span> for two!</p>
          <p class="subtitle">Please share your details below:</p>
        </div>
        <form id="contact-form" action="#" [formGroup]="enquiryForm">
          <div class="form-group">
            <p class="label">Full Name*</p>
            <input type="text" name="fullName" formControlName="fullName" id="fullName" class="input" required>
          </div>
          <div class="form-group">
            <p class="label">Contact No.*</p>
            <input type="tel" name="contact" formControlName="contact" id="contact" class="input" required>
            <div
              *ngIf="enquiryForm.get('contact')?.invalid && (enquiryForm.get('contact')?.dirty ||enquiryForm.get('contact')?.touched)">
              <div *ngIf="enquiryForm.get('contact')?.errors?.['numbersOnly']" class="text-danger">
                Contact number should contain numbers only.
              </div>
            </div>
          </div>
          <div class="form-group">
            <p class="label">Email ID*</p>
            <input type="email" name="email" formControlName="email" id="email" class="input" required>
            <div *ngIf="enquiryForm.get('email')?.invalid && (enquiryForm.get('email')?.dirty ||enquiryForm.get('email')?.touched)">
              <div *ngIf="enquiryForm.get('email')?.errors?.['email']" class="text-danger" translate>
                Email is not valid.</div>
            </div>
          </div>
          <div class="form-group">
            <p class="label">Number of People Travelling*</p>
            <input type="number" name="numberOfPeople" formControlName="numberOfPeople" id="numberOfPeople"
              class="input" required>
          </div>
          <div class="form-group">
            <p class="label">Month of Travel*</p>
            <select name="monthOfTravel" formControlName="monthOfTravel" id="monthOfTravel" class="input" required>
              <option value="" disabled selected>Select a month</option>
              <option value="Nov 2024">Nov 2024</option>
              <option value="Dec 2024">Dec 2024</option>
              <option value="Jan 2025">Jan 2025</option>
              <option value="Feb 2025">Feb 2025</option>
              <option value="Mar 2025">Mar 2025</option>
              <option value="Apr 2025">Apr 2025</option>
            </select>
          </div>
          <div class="form-group">
            <p class="label">Select Your Itinerary*</p>
            <select name="itinary" formControlName="itinary" id="monthOfTravel" class="input" required>
              <option value="" disabled selected>Select Itinerary</option>
              <option value="3 Nights Sunday Sailing">3 Nights Sunday Sailing</option>
              <option value="2 Nights Wednesday Sailing">2 Nights Wednesday Sailing</option>
              <option value="2 Nights Friday Sailing">2 Nights Friday Sailing</option>
            </select>
          </div>
          <button *ngIf="enquiryForm.valid" class="join-button mt30" type="submit" (click)="submitForm()">
            Habibi Let's Go
          </button>
          <button *ngIf="!enquiryForm.valid" class="join-button mt30 disabled" type="submit">
            Habibi Let's Go
          </button>
        </form>
        <img src="../../../assets/landing_pages/closeForm.svg" alt="" id="close-popup" class="form-close"
          (click)="closePopup()">
      </div>
    </div>
  </section>
</div>