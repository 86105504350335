export const locationCountryJson = [
    {
        "id": 1,
        "agency_id": 15,
        "language_id": 1,
        "continent": "Gulf",
        "location_name": "India",
        "location_code": "IN"
      },
      {
        "id": 2,
        "agency_id": 1,
        "language_id": 1,
        "continent": "Asia",
        "location_name": "Korea, Democratic People's Republic of\t",
        "location_code": "KR"
      },
      {
        "id": 3,
        "agency_id": 3,
        "language_id": 1,
        "continent": "Asia",
        "location_name": "Thailand",
        "location_code": "TH"
      },
      {
        "id": 4,
        "agency_id": 16,
        "language_id": 1,
        "continent": "Asia",
        "location_name": "Singapore",
        "location_code": "SG"
      },
      {
        "id": 5,
        "agency_id": 17,
        "language_id": 1,
        "continent": "Asia",
        "location_name": "Philippines",
        "location_code": "PH"
      },
      {
        "id": 6,
        "agency_id": 18,
        "language_id": 1,
        "continent": "Asia",
        "location_name": "Myanmar",
        "location_code": "MM"
      },
      {
        "id": 7,
        "agency_id": 19,
        "language_id": 1,
        "continent": "Asia",
        "location_name": "Vietnam",
        "location_code": "VN"
      },
      {
        "id": 8,
        "agency_id": 20,
        "language_id": 1,
        "continent": "Gulf",
        "location_name": "Pakistan",
        "location_code": "PK"
      },
      {
        "id": 9,
        "agency_id": 21,
        "language_id": 1,
        "continent": "Gulf",
        "location_name": "Bangladesh",
        "location_code": "BD"
      },
      {
        "id": 10,
        "agency_id": 22,
        "language_id": 1,
        "continent": "Gulf",
        "location_name": "United Arab Emirates",
        "location_code": "AE"
      },
      {
        "id": 11,
        "agency_id": 23,
        "language_id": 1,
        "continent": "Asia",
        "location_name": "Cambodia",
        "location_code": "KH"
      },
      {
        "id": 12,
        "agency_id": 24,
        "language_id": 1,
        "continent": "Gulf",
        "location_name": "Nepal",
        "location_code": "NP"
      },
      {
        "id": 13,
        "agency_id": 25,
        "language_id": 1,
        "continent": "Gulf",
        "location_name": "Sri Lanka",
        "location_code": "LK"
      },
      {
        "id": 14,
        "agency_id": 26,
        "language_id": 1,
        "continent": "Asia",
        "location_name": "United States",
        "location_code": "US"
      },
      {
        "id": 15,
        "agency_id": 27,
        "language_id": 1,
        "continent": "Asia",
        "location_name": "Japan",
        "location_code": "JP"
      },
      {
        "id": 16,
        "agency_id": 28,
        "language_id": 3,
        "continent": "Asia",
        "location_name": "China",
        "location_code": "CN"
      },
      {
        "id": 17,
        "agency_id": 29,
        "language_id": 1,
        "continent": "Asia",
        "location_name": "United Kingdom",
        "location_code": "GB"
      },
      {
        "id": 18,
        "agency_id": 30,
        "language_id": 1,
        "continent": "Asia",
        "location_name": "Indonesia",
        "location_code": "ID"
      },
      {
        "id": 19,
        "agency_id": 31,
        "language_id": 1,
        "continent": "Asia",
        "location_name": "Mauritius",
        "location_code": "MU"
      },
      {
        "id": 20,
        "agency_id": 32,
        "language_id": 1,
        "continent": "Asia",
        "location_name": "Lao People's Democratic Republic",
        "location_code": "LA"
      },
      {
        "id": 21,
        "agency_id": 33,
        "language_id": 1,
        "continent": "Gulf",
        "location_name": "Ghana",
        "location_code": "GH"
      },
      {
        "id": 22,
        "agency_id": 34,
        "language_id": 3,
        "continent": "Asia",
        "location_name": "Hong Kong",
        "location_code": "HK"
      },
      {
        "id": 23,
        "agency_id": 35,
        "language_id": 1,
        "continent": "Asia",
        "location_name": "Australia",
        "location_code": "AU"
      },
      {
        "id": 24,
        "agency_id": 36,
        "language_id": 1,
        "continent": "Asia",
        "location_name": "Malaysia",
        "location_code": "MY"
      },
      {
        "id": 25,
        "agency_id": 37,
        "language_id": 3,
        "continent": "Asia",
        "location_name": "Taiwan",
        "location_code": "TW"
      },
      {
        "id": 26,
        "agency_id": 38,
        "language_id": 1,
        "continent": "Gulf",
        "location_name": "Bahrain",
        "location_code": "BH"
      },
      {
        "id": 27,
        "agency_id": 39,
        "language_id": 1,
        "continent": "Gulf",
        "location_name": "Kuwait",
        "location_code": "KW"
      },
      {
        "id": 28,
        "agency_id": 40,
        "language_id": 1,
        "continent": "Gulf",
        "location_name": "Oman",
        "location_code": "OM"
      },
      {
        "id": 29,
        "agency_id": 41,
        "language_id": 1,
        "continent": "Gulf",
        "location_name": "Saudi Arabia",
        "location_code": "SA"
      },
      {
        "id": 30,
        "agency_id": 42,
        "language_id": 1,
        "continent": "Gulf",
        "location_name": "Qatar",
        "location_code": "QA"
      },
      {
        "id": 31,
        "agency_id": 43,
        "language_id": 1,
        "continent": "Asia",
        "location_name": "Brunei Darussalam",
        "location_code": "BN"
      }
]