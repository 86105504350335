import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-guest-modal',
  templateUrl: './guest-modal.component.html',
  styleUrls: ['./guest-modal.component.scss'],
})
export class GuestModalComponent  implements OnInit {
  @Input() showGuestPriceDetails: boolean = false;
  @Input() guestPriceDetails!: any;
  @Input() TotalAmount!: any;
  @Input() CurrencyCode_en!:any;
  @Input() CurrencyCode_sc!:any;
  @Input() CurrencyCode_tc!:any;
  @Input() CurrencyCode_arabic!:any;
  @Input() isPGratuityPresent!:any;
  @Output() close = new EventEmitter<void>();
  @Input() languageID!:any;
  constructor() { }

  ngOnInit() {}

  formatNumber(number: number): string {
    if (number == null || isNaN(number)) return '';
    const parts = number.toFixed(2).toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");   
  }
  closeModal() {
    this.close.emit();
  }

}
