import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NavigationEnd, RouteReuseStrategy, Router } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from './components/components.module';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HttpClientModule, HttpClient, HttpClientJsonpModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { GetDataService } from 'src/providers/get-data.service';
import { PostDataService } from 'src/providers/post-data.service';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { registerLocaleData } from '@angular/common';
import localeZh from '@angular/common/locales/zh';
import localeZhExtra from '@angular/common/locales/extra/zh';
import localeZhTW from '@angular/common/locales/zh-Hant';
import localeEn from '@angular/common/locales/en';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { CookieService } from 'ngx-cookie-service';
import { InterceptorService } from 'src/providers/interceptor.service';
import { RedirectComponent } from './components/redirect/redirect.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
registerLocaleData(localeEn, 'en');
registerLocaleData(localeZh, 'zh', localeZhExtra);
registerLocaleData(localeZhTW, 'zh-TW');

@NgModule({
  declarations: [AppComponent, RedirectComponent],
  imports: [
    BrowserModule, 
    IonicModule.forRoot({ innerHTMLTemplatesEnabled: true }), 
    AppRoutingModule,
    ComponentsModule,
    HttpClientModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    SlickCarouselModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    ShareButtonsModule.withConfig({
      debug: true,
    }),
    ShareIconsModule,
    HttpClientJsonpModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, 
    { provide: LOCALE_ID, useValue: 'en' },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    provideAnimationsAsync('noop'),
    GetDataService,
    PostDataService,
    ShareIconsModule,
    CookieService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary, private router: Router) { 
		library.addIconPacks(fas, fab, far);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Set default route after application initialization
        if (event.urlAfterRedirects === '/') {
          this.router.navigate(['home']);
        }
      }
    });
	}
}
