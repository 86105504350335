import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FooterComponent } from './footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {  HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FooterBarComponent } from './footer-bar/footer-bar.component';
import { ChatIconComponent } from './chat-icon/chat-icon.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { LandingFormComponent } from './landing-form/landing-form.component';
import { ArLandingFormComponent } from './ar-landing-form/ar-landing-form.component';
import { GameIconComponent } from './game-icon/game-icon.component';
import { GuestModalComponent } from './guest-modal/guest-modal.component';
import { PromoModalComponent } from './promo-modal/promo-modal.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        FooterBarComponent,
        ChatIconComponent,
        LandingFormComponent,
        ArLandingFormComponent,
        GameIconComponent,
        GuestModalComponent,
        PromoModalComponent
    ],
    imports: [    
		CommonModule,
		IonicModule,
        MatButtonModule, 
        NgxIntlTelInputModule,
        MatMenuModule,
        FontAwesomeModule,
        FormsModule, ReactiveFormsModule,
        TranslateModule.forChild({
            loader: {
              provide: TranslateLoader,
              useFactory: createTranslateLoader,
              deps: [HttpClient]
            }
          })
	],
    exports: [
        HeaderComponent,
        FooterComponent,
        FooterBarComponent,
        ChatIconComponent,
        LandingFormComponent,
        ArLandingFormComponent,
        GameIconComponent,
        GuestModalComponent,
        PromoModalComponent
    ],
    schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule {}