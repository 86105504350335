import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {RedirectComponent} from '../app/components/redirect/redirect.component';
const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'search-results',
    loadChildren: () => import('./search-results/search-results.module').then( m => m.SearchResultsPageModule)
  },
  {
    path: 'special-pages/:id',
    loadChildren: () => import('./special-pages/special-pages.module').then( m => m.SpecialPagesPageModule)
  },
  {
    path: 'deal-details/:itinerary_id/:vassel_id',
    loadChildren: () => import('./deal-details/deal-details.module').then( m => m.DealDetailsPageModule)
  },
  {
    path: 'deals-guest',
    loadChildren: () => import('./deals-guest/deals-guest.module').then( m => m.DealsGuestPageModule)
  },
  {
    path: 'deals-room-type',
    loadChildren: () => import('./deals-room-type/deals-room-type.module').then( m => m.DealsRoomTypePageModule)
  },
  {
    path: 'guest-info',
    loadChildren: () => import('./guest-info/guest-info.module').then( m => m.GuestInfoPageModule)
  },
  {
    path: 'guests-info-policy',
    loadChildren: () => import('./guests-info-policy/guests-info-policy.module').then( m => m.GuestsInfoPolicyPageModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment/payment.module').then( m => m.PaymentPageModule)
  },
  {
    path: 'payment-confirmation',
    loadChildren: () => import('./payment-confirmation/payment-confirmation.module').then( m => m.PaymentConfirmationPageModule)
  },
  {
    path: 'terms-conditions',
    loadChildren: () => import('./terms-conditions/terms-conditions.module').then( m => m.TermsConditionsPageModule)
  },
  {
    path: 'deals',
    loadChildren: () => import('./deals/deals.module').then( m => m.DealsPageModule)
  },
  // {
  //   path: 'FamilyInd',
  //   loadChildren: () => import('./family-ind/family-ind.module').then( m => m.FamilyIndPageModule)
  // },
  // {
  //   path: 'coupleInd',
  //   redirectTo: 'CoupleInd',
  //   pathMatch: 'full'
  // },
  // {
  //   path: 'CoupleInd',
  //   loadChildren: () => import('./couple-ind/couple-ind.module').then( m => m.CoupleIndPageModule)
  // },
  // {
  //   path: 'YouthInd',
  //   loadChildren: () => import('./youth-ind/youth-ind.module').then( m => m.YouthIndPageModule)
  // },
  // {
  //   path: 'ar/FamilyInd',
  //   loadChildren: () => import('./family-ind-arabic/family-ind-arabic.module').then( m => m.FamilyIndArabicPageModule)
  // },
  // {
  //   path: 'thank-you',
  //   loadChildren: () => import('./thank-you/thank-you.module').then( m => m.ThankYouPageModule)
  // },
  // {
  //   path: 'ar/CoupleInd',
  //   loadChildren: () => import('./couple-ind-arabic/couple-ind-arabic.module').then( m => m.CoupleIndArabicPageModule)
  // },
  {
    path: 'destinations/:slug',
    loadChildren: () => import('./destinations/destinations.module').then( m => m.DestinationsPageModule)
  },
  {
    path: 'our-ship/:ship_id',
    loadChildren: () => import('./our-ship/our-ship.module').then( m => m.OurShipPageModule)
  },
  {
    path: 'experiences',
    loadChildren: () => import('./experiences/experiences.module').then( m => m.ExperiencesPageModule)
  },
  {
    path: 'know-before-you-sail',
    loadChildren: () => import('./know-before-you-sail/know-before-you-sail.module').then( m => m.KnowBeforeYouSailPageModule)
  },
  {
    path: 'prepare-for-your-cruise',
    loadChildren: () => import('./prepare-for-your-cruise/prepare-for-your-cruise.module').then( m => m.PrepareForYourCruisePageModule)
  },
  {
    path: 'bill-of-rights',
    loadChildren: () => import('./bill-of-rights/bill-of-rights.module').then( m => m.BillOfRightsPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'cookie-policy',
    loadChildren: () => import('./cookie-policy/cookie-policy.module').then( m => m.CookiePolicyPageModule)
  },
  {
    path: 'terms-of-usage',
    loadChildren: () => import('./terms-of-usage/terms-of-usage.module').then( m => m.TermsOfUsagePageModule)
  },
  {
    path: 'passage-terms',
    loadChildren: () => import('./passage-terms/passage-terms.module').then( m => m.PassageTermsPageModule)
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () => import('./terms-and-conditions/terms-and-conditions.module').then( m => m.TermsAndConditionsPageModule)
  },
  {
    path: 'careers',
    loadChildren: () => import('./careers/careers.module').then( m => m.CareersPageModule)
  },
  {
    path: 'press-releases',
    loadChildren: () => import('./press-releases/press-releases.module').then( m => m.PressReleasesPageModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./contact-us/contact-us.module').then( m => m.ContactUsPageModule)
  },
  {
    path: 'about-us',
    loadChildren: () => import('./about-us/about-us.module').then( m => m.AboutUsPageModule)
  },
  {
    path: 'vr',
    loadChildren: () => import('./view360vr/view360vr.module').then( m => m.View360vrPageModule)
  },  
  {
    path: 'deals-room',
    loadChildren: () => import('./deals-room/deals-room.module').then( m => m.DealsRoomPageModule)
  },
  {
    path: 'deals-value',
    loadChildren: () => import('./deals-value/deals-value.module').then( m => m.DealsValuePageModule)
  },
  // {
  //   path: 'ae',
  //   loadChildren: () => import('./ae/ae.module').then( m => m.AePageModule)
  // },
  // {
  //   path: 'ae/thank-you',
  //   loadChildren: () => import('./ae/ae.module').then( m => m.AePageModule)
  // },
  {
    path: '**',
    component: RedirectComponent,
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
