import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, shareReplay, tap } from 'rxjs/operators';
import { GetDataService } from './get-data.service';
import { locationCountryJson } from 'src/config/LocationMaster';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class PostDataService {
  domain_name = environment.domain_name;
  private loginRequest$ : Observable<any> | null = null;;
  constructor(private http: HttpClient,private getData : GetDataService,private cookieService: CookieService) { }

  /** 
   * make a post request to endpoint with params object
   *
   * @param 
   * @returns 
   */

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }

  private handlesError(error: HttpErrorResponse) {
    let errormessage = '';
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
      errormessage = 'An error occurred: ' + error.error;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
        errormessage =  `Backend returned code ${error.status}, body was: `+ JSON.stringify(error.error);
    }
    // Return an observable with a user-facing error message.
    errormessage += 'Something went wrong! Please try again later.';
    return throwError(() => new Error(errormessage));
  }

  postDataWithoutToken(api:string, params:any): Observable<any> {
    let api_url = environment.API_URL + api;
    let headers_new = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http
      .post(`${api_url}`, params, headers_new)
      .pipe(catchError(this.handlesError));
  }
  postDataToken(api:string, params:any): Observable<any> {
    let api_url = api;
    let headers_new = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http
      .post(`${api_url}`, params, headers_new)
      .pipe(catchError(this.handlesError));
  }
  postDataWithoutParam(api:string, token:any): Observable<any> {
    let api_url = environment.API_URL + api;
    let headers_new = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer  ${token}`
      }),
    };
    return this.http
      .post(`${api_url}/`, '', headers_new)
      .pipe(catchError(this.handlesError));
  }
  postDataWithToken(api:string, params:any,token:any): Observable<any> {
    let api_url = environment.API_URL + api;
    let headers_new = {
      headers: new HttpHeaders(
        { 
          'Content-Type': 'application/json',
          // 'Authorization': `Bearer  ${token}`
        }),
    };
    return this.http
      .post(`${api_url}/`, params, headers_new)
      .pipe(catchError(this.handlesError));
  }
  postDataWithTokenAndId(api:string, params:any,token:any,id:any): Observable<any> {
    let api_url = environment.API_URL + api;
    let headers_new = {
      headers: new HttpHeaders(
        { 
          'Content-Type': 'application/json',
          // 'Authorization': `Bearer  ${token}`,
          'id': id,
        }),
    };
    return this.http
      .post(`${api_url}/`, params, headers_new)
      .pipe(catchError(this.handlesError));
  }

  postDataWithTokenAndLocation(api:string, params:any,token:any): Observable<any> {
    let langId = localStorage.getItem('LanguageID') ? localStorage.getItem('LanguageID') : 1;
    let locId = localStorage.getItem('LocationID');
    let api_url = environment.API_URL + api;
    let headers_new = {
      headers: new HttpHeaders(
        { 
          'Content-Type': 'application/json',
          // 'Authorization': `Bearer  ${token}`,
          'LocationID': Number(locId),
          "LanguageID": Number(langId)
        }),
    };
    return this.http
      .post(`${api_url}/`, params, headers_new)
      .pipe(catchError(this.handlesError));
  }

  postDataWithoLocation(api:string, token:any): Observable<any> {
    let langId = localStorage.getItem('LanguageID') ? localStorage.getItem('LanguageID') : 1;
    let locId = localStorage.getItem('LocationID');
    let api_url = environment.API_URL + api;
    let headers_new = {
      headers: new HttpHeaders(
        { 
          'Content-Type': 'application/json',
          // 'Authorization': `Bearer  ${token}`,
          'LocationID': Number(locId),
          "LanguageID": Number(langId)
        }),
    };
    return this.http
      .post(`${api_url}/`, '', headers_new)
      .pipe(catchError(this.handlesError));
  }
  async getLocation(): Promise<string | undefined> {
    try {
      const data: any = await this.getData.getIPAddressC();
      let countries = locationCountryJson;
      let currentLocation : any = countries.find((item: any) => item.location_code.toLowerCase() === data.address.country_code.toLowerCase());
      if (!currentLocation) {
        currentLocation = { id: 4, language_id: 1, location_code: 'SG' }; // Provide default values
      }
      localStorage.setItem('current_ip', data.ip);
      let savedIP = localStorage.getItem('LocationID');
      if(savedIP != currentLocation?.id){
        localStorage.setItem('LocationID', currentLocation?.id.toString());
        this.cookieService.set('ip_address', data.ip, 1, '/', this.domain_name);
        this.cookieService.set('rwc_usr_ip_source', currentLocation.location_code, 1, '/', this.domain_name);
        this.cookieService.set('rwc_usr_cookie_notice', currentLocation.location_code, 1, '/', this.domain_name);
        this.cookieService.set('rwc_site_lang','EN', 1, '/', this.domain_name);
        localStorage.setItem('current_ip', data.ip);
        localStorage.setItem('LanguageID', currentLocation?.language_id.toString());
        localStorage.setItem('location_code',currentLocation.location_code);
        this.cookieService.set('rwc_site_lang_variant','null', 1, '/', this.domain_name);
        localStorage.setItem('Language','EN');
      }
      return currentLocation?.id.toString();
    } catch (error:any) {
      const data: any = await this.getData.getIPAddress();
      let countries = locationCountryJson;
      let currentLocation : any = countries.find((item: any) => item.location_code.toLowerCase() === data.country.toLowerCase());
      if (!currentLocation) {
        currentLocation = { id: 4, language_id: 1, location_code: 'SG' }; // Provide default values
      }
      localStorage.setItem('current_ip', data.ip);
      let savedIP = localStorage.getItem('LocationID');
      if(savedIP != currentLocation?.id){
        localStorage.setItem('LocationID', currentLocation?.id.toString());
        this.cookieService.set('ip_address', data.ip, 1, '/', this.domain_name);
        this.cookieService.set('rwc_usr_ip_source', currentLocation.location_code, 1, '/', this.domain_name);
        this.cookieService.set('rwc_usr_cookie_notice', currentLocation.location_code, 1, '/', this.domain_name);
        currentLocation?.language_id == 1 ? this.cookieService.set('rwc_site_lang','EN', 1, '/', this.domain_name) : this.cookieService.set('rwc_site_lang','CN', 1, '/', this.domain_name);
        localStorage.setItem('current_ip', data.ip);
        localStorage.setItem('LanguageID', currentLocation?.language_id.toString());
        localStorage.setItem('location_code',currentLocation.location_code);
        this.cookieService.set('rwc_site_lang_variant','null', 1, '/', this.domain_name);
        currentLocation?.language_id == 1 ? localStorage.setItem('Language','EN') : localStorage.setItem('Language','CN');
      }
      return currentLocation?.id.toString();
    }
  }
  loginUser(){
    if (!this.loginRequest$) {
      let finalParam = {
        parameter: "U2FsdGVkX1+7t518vzBeOYAWnJwdVyxtCzKZ4t+eyMKc2/Am9yPea40BLQ1T8SY8xmZKEYHhQt458QjbexBD/vb/uNI/1fBmFy1zNjf42pyyYucJmGEdOHyTf6+bAVRFyKLbgXHZtoTgaiG2tz87jeIrqkzsJEwhb2w9p++e3ds="
      };
  
      this.loginRequest$ = this.postDataWithoutToken('auth/login', finalParam).pipe(
        shareReplay(1), // Cache the result and share it among subscribers
        catchError(error => {
          this.loginRequest$ = null; // Reset cache on error to allow re-try
          return error; // Handle or re-throw error as needed
        })
      );
    }
  
    return new Promise((res, rej) => {
      this.loginRequest$?.subscribe({
        next: (data) => {
          localStorage.setItem('acces-token', data.access_token);
          localStorage.setItem('LanguageID', '1');
          res(data);
        },
        error: (err) => {
          rej(err);
        },
      });
    });
  }

  filterSearchData(params:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.postDataWithTokenAndLocation('api/v1/filter-search', params,access_token)?.subscribe({
        next: (data) => {
          res(data);
        },
        error: (err) => {
          rej(err);
        }
      });
    })
  }

  sendNewsLetterData(params:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.postDataWithToken('api/v1/homepage/newsletter', params,access_token)?.subscribe({
        next: (data) => {
          res(data);
        },
        error: (err) => {
          rej(err);
        }
      });
    })
  }

  locationMaster(){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.postDataWithoutParam('api/v1/homepage/location&language', access_token)?.subscribe({
        next: (data) => {
          res(data);
        },
        error: (err) => {
          rej(err);
        }
      });
    })
  }

  filterSearchResult(params:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.postDataWithTokenAndLocation('api/v1/filter-search-result', params,access_token)?.subscribe({
        next: (data) => {
          res(data);
        },
        error: (err) => {
          rej(err);
        }
      });
    })
  }

  specialPagesList(){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.postDataWithoLocation('api/v1/speciel-page-list',access_token)?.subscribe({
        next: (data) => {
          res(data);
        },
        error: (err) => {
          rej(err);
        }
      });
    })
  }

  specialPagessData(id:any){
    let access_token = localStorage.getItem('acces-token')
    let apiUrl = 'api/v1/special-search/' + id
    return new Promise((res, rej) => {
      this.postDataWithoLocation(apiUrl,access_token)?.subscribe({
        next: (data) => {
          res(data);
        },
        error: (err) => {
          rej(err);
        }
      });
    })
  }

  getDealDetails(params:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.postDataWithTokenAndLocation('api/v1/package/details', params,access_token)?.subscribe({
        next: (data) => {
          res(data);
        },
        error: (err) => {
          rej(err);
        }
      });
    })
  }

  getCabinList(params:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.postDataWithTokenAndLocation('api/v1/package/cabin-types', params,access_token)?.subscribe({
        next: (data) => {
          res(data);
        },
        error: (err) => {
          rej(err);
        }
      });
    })
  }

  getPromoPrice(params:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.postDataWithTokenAndLocation('api/v1/promoprice/get-PriceBy-Promo', params,access_token)?.subscribe({
        next: (data) => {
          res(data);
        },
        error: (err) => {
          rej(err);
        }
      });
    })
  }

  applyPromoCode(params:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.postDataWithTokenAndLocation('api/v1/promoprice/get-Discount-price', params,access_token)?.subscribe({
        next: (data) => {
          res(data);
        },
        error: (err) => {
          rej(err);
        }
      });
    })
  }

  createLead(params:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.postDataWithTokenAndLocation('api/v1/leads/create', params,access_token)?.subscribe({
        next: (data) => {
          res(data);
        },
        error: (err) => {
          rej(err);
        }
      });
    })
  }
  createLeadsGuest(params:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.postDataWithToken('api/v1/leads/guests', params,access_token)?.subscribe({
        next: (data) => {
          res(data);
        },
        error: (err) => {
          rej(err);
        }
      });
    })
  }
  sendLeadsEmergencyContact(params:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.postDataWithToken('api/v1/leads/emergency-contact', params,access_token)?.subscribe({
        next: (data) => {
          res(data);
        },
        error: (err) => {
          rej(err);
        }
      });
    })
  }
  createBooking(params:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.postDataWithTokenAndLocation('api/v1/booking/create-booking', params,access_token)?.subscribe({
        next: (data) => {
          res(data);
        },
        error: (err) => {
          rej(err);
        }
      });
    })
  }
  makePaymentById(params:any,id:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.postDataWithTokenAndId('api/v1/payment', params,access_token,id)?.subscribe({
        next: (data) => {
          res(data);
        },
        error: (err) => {
          rej(err);
        }
      });
    })
  }
  makePaymentByBookingNo(params:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.postDataWithToken('api/v1/payment', params,access_token)?.subscribe({
        next: (data) => {
          res(data);
        },
        error: (err) => {
          rej(err);
        }
      });
    })
  }
  paypost(api:string): Observable<any> {
    let headers_new = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
      }),
    };
    return this.http
      .post(`${api}`, '', headers_new)
      .pipe(catchError(this.handlesError));
  }
  generateToken(param:any){
    return new Promise((res, rej) => {
      this.postDataToken(environment.payment_tokenise_api_url, param)?.subscribe({
        next: (data) => {
          res(data);
        },
        error: (err) => {
          rej(err);
        }
      });
    })
  }

  cabinBlock(param:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.postDataWithTokenAndLocation('api/v1/promoprice/cabin-block',param,access_token)?.subscribe({
        next: (data) => {
          res(data);
        },
        error: (err) => {
          rej(err);
        }
      });
    })
  }
  extendCabinBlockTime(param:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.postDataWithTokenAndLocation('api/v1/promoprice/cabin-extend',param,access_token)?.subscribe({
        next: (data) => {
          res(data);
        },
        error: (err) => {
          rej(err);
        }
      });
    })
  }
  fetchDealsList(param:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.postDataWithTokenAndLocation('api/v1/deals/currentDeals',param,access_token)?.subscribe({
        next: (data) => {
          res(data);
        },
        error: (err) => {
          rej(err);
        }
      });
    })
  }
  postEnquiryForm(param:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.postDataWithToken('enquire/family-enquire',param,access_token)?.subscribe({
        next: (data) => {
          res(data);
        },
        error: (err) => {
          rej(err);
        }
      });
    })
  }

  contactUsData(param:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.postDataWithToken('api/v1/webpages/contactUs', param,access_token)?.subscribe({
        next: (data) => {
          res(data);
        },
        error: (err) => {
          rej(err);
        }
      });
    })
  }
  postDeviceId(id:any){
    let access_token = localStorage.getItem('acces-token')
    let finalParam = {
      "device_id":id
    }
    return new Promise((res, rej) => {
      this.postDataWithToken('user/device', finalParam,access_token)?.subscribe((data) => {
        res(data)
      }), (err:any) => {
        rej(err);
      }
    })
    
  }
}
