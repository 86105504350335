import { Component, OnInit } from '@angular/core';
import { register } from 'swiper/element/bundle';
import { TranslateService } from '@ngx-translate/core';
import { IpAddressService } from 'src/providers/ip-address.service';
import { GetDataService } from 'src/providers/get-data.service';
import { CookieService } from 'ngx-cookie-service';

register();
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private translateService : TranslateService,private ipService : IpAddressService,private getData : GetDataService,private cookieService: CookieService) {
    this.translateService.setDefaultLang('en');
    this.translateService.addLangs(['en', 'si', 'tr','ar']);
    let langId= localStorage.getItem('LanguageID')
    if(langId == null){
      localStorage.setItem('LanguageID', '1');
      this.changeLanguage('1')
    }else{
      this.changeLanguage(langId)
    }
    let isArabicValue = localStorage.getItem('isArabic')
    if(isArabicValue == null){
      localStorage.setItem('isArabic', 'no');
    }
  }

  async ngOnInit() {
    let locid = localStorage.getItem('LocationID');
    let rwc_usr_ip_source = this.cookieService.get('rwc_usr_ip_source');
    if(locid == null || !rwc_usr_ip_source) {
      await this.ipService.storeCookies();
    }
    await this.ipService.startMonitoring();
  }

  changeLanguage(id:any){
    if(id === '1'){
      let language = 'en';
      this.translateService.use(language);
    }else if(id === '2'){
      let language = 'tr';
      this.translateService.use(language);
    }else if(id === '3'){
      let language = 'si';
      this.translateService.use(language);
    }else{
      let language = 'ar';
      document.documentElement.dir = 'rtl';
      this.translateService.use(language);
    }
  }
}
