// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    API_URL: 'https://api.rwcruisesuat.com/',
    username:'partha@maukaa.me',
    paymentTokenURL: 'https://payment.rwcruisesuat.com/payment?token=',
    Know_Before_You_Sail: 'https://rwcruisesuat.com/know-before-you-sail',
    Prepare_For_Your_Cruise: 'https://rwcruisesuat.com/prepare-for-your-cruise',
    genting_reward: 'https://gr.rwcruises.com/',
    book_cruise: 'https://rwcruisesuat.com/deals',
    billsOfRight: 'https://rwcruisesuat.com/bill-of-rights',
    privacyPolicy: 'https://rwcruisesuat.com/privacy-policy',
    cookiePolicy: 'https://rwcruisesuat.com/cookie-policy',
    termsOfUse:'https://rwcruisesuat.com/terms-of-usage',
    passageTerms:'https://rwcruisesuat.com/passage-terms',
    termsandconditions:'https://rwcruisesuat.com/terms-and-conditions',
    payment_tokenise_api_url: 'https://payment.rwcruisesuat.com/tokenize',
    deals_share: 'https://rwcruise.rwcruisesuat.com/deal-details/',
    checkin: 'https://webcheckin.rwcruises.com/',
    contactUs: 'https://rwcruisesuat.com/contact-us',
    careers: 'https://rwcruisesuat.com/careers',
    mediaResources : 'https://rwcruisesuat.com/press-releases',
    partnerResource: 'https://rwcruises.ibsplc.net/iTravel/production/spring/loginFlow',
    popup_image :  'https://media.rwcruisesuat.com/Pop_Up.jpg',
    popup_image2:  'https://media.rwcruisesuat.com/Pop_Up2.png',
    domain_name: '.rwcruisesuat.com',
    game_icon: 'https://media.rwcruisesuat.com/icon2124.png',
    base_domain_url: 'https://rwcruisesuat.com/',
    popup_image_my : 'https://media.rwcruisesuat.com/malaysian_popup.jpg',
    image_Url :'https://media.rwcruisesuat.com/',
    siteKey : '6Ld8N5snAAAAAEexAtYskifDh_p7zL8BAkpRszQP',
    bing_bing_popup:'https://media.rwcruisesuat.com/Pop_up_2.jpg',
  };
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
  