import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { PostDataService } from 'src/providers/post-data.service';

@Component({
  selector: 'app-landing-form',
  templateUrl: './landing-form.component.html',
  styleUrls: ['./landing-form.component.scss'],
})
export class LandingFormComponent  implements OnInit {
  @ViewChild('popupElement') popupElement: ElementRef | undefined;
  enquiryForm: FormGroup;
  @Input() page_name : string;

  constructor(private formBuilder: FormBuilder, private postData : PostDataService,private router: Router, private toast: ToastController) { }

  ngOnInit() {
    this.initForm();
  }

  openPopup(){
    if (this.popupElement) {
      this.popupElement.nativeElement.classList.add('active');
    }
  }
  closePopup() {
    if (this.popupElement) {
      this.popupElement.nativeElement.classList.remove('active');
    }
  }
  initForm(){
    this.enquiryForm = this.formBuilder.group({
      fullName:['',Validators.required],
      contact:['',[Validators.required, this.numbersOnlyValidator]],
      email:['',[Validators.required, this.customEmailValidator]],
      numberOfPeople:['',Validators.required],
      monthOfTravel:['',Validators.required],  
      itinary:['',Validators.required]
    });
  }
  customEmailValidator(control: any): { [key: string]: boolean } | null {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (control.value && !emailRegex.test(control.value)) {
      return { 'email': true };
    }
    return null;
  }
  numbersOnlyValidator(control: any): { [key: string]: boolean } | null {
    const numbersOnlyRegex = /^[0-9]*$/;  // Regex for numbers only
    if (control.value && !numbersOnlyRegex.test(control.value)) {
      return { 'numbersOnly': true };
    }
    return null;
  }
  submitForm(){
    let params ={
      "name": this.enquiryForm.get('fullName')?.value,
      "email": this.enquiryForm.get('email')?.value,
      "contact_number": this.enquiryForm.get('contact')?.value,
      "number_of_people": this.enquiryForm.get('numberOfPeople')?.value,
      "month_of_travel": this.enquiryForm.get('monthOfTravel')?.value,
      "itinerary": this.enquiryForm.get('itinary')?.value,
      "page_name": this.page_name
    } 
    this.postData.postEnquiryForm(params).then((res:any) => {
      if(res.message == 'Enquire succesfull'){
        this.router.navigateByUrl('/thank-you',{state: {lang:'en'}})
      }else {
        this.presentToast('Something Went Wrong!')
      }
    }).catch(err => this.presentToast('Something Went Wrong!'))
  }
  async presentToast(message: string) {
    const toast = await this.toast.create({
      message: message,
      duration: 1500,
      position: 'top',
      cssClass: 'custom-toast'
    });

    await toast.present();
  }

}
