import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { GetDataService } from './get-data.service';
import { locationCountryJson } from 'src/config/LocationMaster';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { AlertController } from '@ionic/angular';
import { v4 as uuidv4 } from 'uuid';
import { PostDataService } from './post-data.service';

@Injectable({
  providedIn: 'root'
})
export class IpAddressService {
  domain_name = environment.domain_name;
  constructor(private cookieService: CookieService, private getData: GetDataService, private translateService: TranslateService, private alert : AlertController,
    private postData : PostDataService
  ) { }
  startMonitoring() {
    setInterval(() => {
      this.checkForIPAddressChange();
    }, 5000);
  }

  private async checkForIPAddressChange() {
    const currentIPAddress = await this.getCurrentIPAddress();
    const storedIPAddress = this.getStoredIPAddress();
    // console.log(currentIPAddress,storedIPAddress, currentIPAddress !== storedIPAddress)
    if (currentIPAddress !== storedIPAddress) {
      // localStorage.removeItem('LocationID')
      this.getLocation(storedIPAddress);
    }
    const currentLanguage = this.cookieService.get('rwc_site_lang');
    const storedLanguage = localStorage.getItem('Language');
    // console.log(currentLanguage,storedLanguage, currentLanguage !== storedLanguage)
    if(currentLanguage !== storedLanguage){
      if(currentLanguage === 'CN' && this.cookieService.get('rwc_site_lang_variant') == 'null'){
        localStorage.setItem('LanguageID','2');
        localStorage.setItem('Language','CN');
      }else if(currentLanguage === 'CN' && this.cookieService.get('rwc_site_lang_variant') == 'SC'){
        localStorage.setItem('LanguageID','3');
        localStorage.setItem('Language','CN');
      }else if(this.cookieService.get('rwc_site_lang_variant') == 'AR'){
        localStorage.setItem('LanguageID','4');
        localStorage.setItem('Language','AR');
      }else{
        localStorage.setItem('LanguageID','1');
        localStorage.setItem('Language','EN');
      }
    }
  }


  private async getCurrentIPAddress(): Promise<string> {
    try {
      const data: any = await localStorage.getItem('location_code');
      return data;
    } catch (error) {
      console.error('Error fetching IP address:', error);
      return ''; // Handle error gracefully, return empty string or handle accordingly
    }
  }

  private getStoredIPAddress(): string {
    return this.cookieService.get('rwc_usr_ip_source');
  }

  private refreshApp() {
    window.location.reload();
  }

  async getLocation(ip:any=null){
    // const data: any = await this.getData.getCountryfromIPAddress(ip);
    let countries = locationCountryJson;
    let currentLocation = countries.find((item: any) => item.location_code.trim().toLowerCase() === ip.trim().toLowerCase());
    if (currentLocation?.id) {
      localStorage.setItem('LocationID', currentLocation.id.toString());
      localStorage.setItem('location_code', ip);
      // localStorage.setItem('LanguageID', currentLocation.language_id.toString());
      this.cookieService.set('rwc_usr_cookie_notice', ip, 1, '/', this.domain_name);
      this.cookieService.set('rwc_site_lang', 'EN', 1, '/', this.domain_name);
    }
    // await this.refreshApp();
  }

  async storeCookies(){
    let deviceId = localStorage.getItem('device_id');
    if (!deviceId) {
      deviceId = uuidv4();  // Generates a new UUID
      localStorage.setItem('device_id', deviceId);
    }
    // try {
      const data : any = await this.getData.getIPAddress();
      let countries = locationCountryJson;
      let currentLocation : any = countries.find((item: any) => item.location_code.toLowerCase() === data.country.toLowerCase());
      if (!currentLocation) {
        currentLocation = { id: 4, language_id: 1, location_code: 'SG' }; // Provide default values
      }
      this.cookieService.set('ip_address', data.ip, 1, '/', this.domain_name);
      this.cookieService.set('rwc_usr_ip_source', currentLocation.location_code, 1, '/', this.domain_name);
      this.cookieService.set('rwc_usr_cookie_notice', currentLocation.location_code, 1, '/', this.domain_name);
      this.cookieService.set('rwc_site_lang', 'EN', 1, '/', this.domain_name);
      this.cookieService.set('rwc_site_lang_variant','null', 1, '/', this.domain_name);
      localStorage.setItem('LocationID', currentLocation?.id.toString());
      localStorage.setItem('location_code',currentLocation.location_code)
      localStorage.setItem('Language','EN');
      await this.postData.postDeviceId(deviceId);
    // } catch (error: any) {
    //     // Show the alert only if the error indicates a permission denial
    //     if (error?.PERMISSION_DENIED) {
    //       alert('To allow location access, please enable it in your device’s settings or browser permissions.');
    //     }
    //     console.error("Error fetching location:", error);
    // }
    
    
  }
}
