import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-game-icon',
  templateUrl: './game-icon.component.html',
  styleUrls: ['./game-icon.component.scss'],
})
export class GameIconComponent  implements OnInit {
  languageId : any;
  constructor() { }

  ngOnInit() {
    this.languageId = localStorage.getItem('LanguageID');    
  }

}
