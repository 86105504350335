import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class IonLoaderService {
  isLoading: boolean = false;
  activeLoader:any;
  constructor(public loadingController: LoadingController) { }

  async simpleLoader() {
    if (!this.isLoading) {
      this.isLoading = true;
      this.activeLoader = await this.loadingController.create({
          message: 'Loading...',
          cssClass:'loader-css-class',
      })
      this.activeLoader.present();
    }
  }
  async dismissLoader() {
    if (this.isLoading && this.activeLoader) {
      this.isLoading = false;
      await this.activeLoader.dismiss();
    }else{
      this.isLoading = false;
      await this.loadingController.dismiss();
    }
  }
  autoLoader() {
    this.loadingController.create({
      message: 'Loader hides after 4 seconds',
      duration: 4000
    }).then((response) => {
      response.present();
      response.onDidDismiss().then((response) => {
        //dismiss
      });
    });
  } 
}
