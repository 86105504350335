import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GetDataService {
  
  constructor(private http: HttpClient) { }

  getDataFromAPIWithoitParam(api:string,token:any): Observable<any> {
    let api_url = environment.API_URL + api;
    let headers_new = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          // 'Authorization': `Bearer  ${token}`
        }),
    };
    return this.http
      .get(`${api_url}`, headers_new)
      .pipe(catchError(this.handleError('Add Student')));
  }
  getDataFromAPIWithParams(api:string, token:any): Observable<any> {
    let langId = localStorage.getItem('LanguageID') ? localStorage.getItem('LanguageID') : 1;
    let locId = localStorage.getItem('LocationID');
    let api_url = environment.API_URL + api;
    let headers_new = {
      headers: new HttpHeaders(
        { 
          'Content-Type': 'application/json',
          // 'Authorization': `Bearer  ${token}`,
          'LocationID': Number(locId),
          "LanguageID": Number(langId)
        })
    };
    return this.http
      .get(`${api_url}`, headers_new)
      .pipe(catchError(this.handleError('Add Student')));
  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }

  getHomePageData(){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.getDataFromAPIWithParams('api/v1/homepage/title',access_token)?.subscribe((data) => {
        res(data);
      }), (err:any) => {
        rej(err);
      }
    })
  }
  getMenu(){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.getDataFromAPIWithParams('api/v1/homepage/menubar',access_token)?.subscribe((data) => {
        res(data);
      }), (err:any) => {
        rej(err);
      }
    })
  }
  getBookingNo(){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.getDataFromAPIWithoitParam('api/v1/leads/booking-no',access_token)?.subscribe((data) => {
        res(data);
      }), (err:any) => {
        rej(err);
      }
    })
  }
  getIPAddressC() {
    return new Promise((resolve, reject) => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (pos: any) => {
                    const { latitude, longitude } = pos.coords;
                    const url = `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`;

                    this.http.get(url).subscribe({
                        next: (data) => {
                            resolve(data); // Resolve with location data
                        },
                        error: (error) => {
                            console.error('Error fetching data:', error);
                            reject(error); // Reject on HTTP error
                        }
                    });
                },
                (err: GeolocationPositionError) => {
                    // If the error code indicates permission denial, add custom error property
                    if (err.code === err.PERMISSION_DENIED) {
                      reject({ PERMISSION_DENIED: true });
                    } else {
                        reject(err); // Handle other geolocation errors
                    }
                },
                { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
            );
        } else {
            reject("Geolocation is not supported by this browser.");
        }
    });
  }
  getIPAddress() {
    return new Promise((res, rej) => {
      this.http.get('https://ipinfo.io/json')?.subscribe((data) => {
        res(data);
      }), (err:any) => {
        rej(err);
      }
    })
  }

  getCountryfromIPAddress(ip:any) {
    return new Promise((res, rej) => {
      this.http.jsonp(`https://reallyfreegeoip.org/json/${ip}`,'callback')?.subscribe((data) => {
        res(data);
      }), (err:any) => {
        rej(err);
      }
    })
  }

  getCelebrationPackages(slug:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.getDataFromAPIWithoitParam(`api/v1/webpages/webpage?slug=${slug}`,access_token)?.subscribe((data) => {
        res(data);
      }), (err:any) => {
        rej(err);
      }
    })
  }

  getDestination(slug:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.getDataFromAPIWithoitParam(`api/v1/webpages/webpage?slug=${slug}`,access_token)?.subscribe((data) => {
        res(data);
      }), (err:any) => {
        rej(err);
      }
    })
  }

  getOurShip(slug:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.getDataFromAPIWithoitParam(`api/v1/webpages/webpage?slug=${slug}`,access_token)?.subscribe((data) => {
        res(data);
      }), (err:any) => {
        rej(err);
      }
    })
  }

  getEntertainment(slug:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.getDataFromAPIWithoitParam(`api/v1/webpages/webpage?slug=${slug}`,access_token)?.subscribe((data) => {
        res(data);
      }), (err:any) => {
        rej(err);
      }
    })
  }

  getMice(slug:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.getDataFromAPIWithoitParam(`api/v1/webpages/webpage?slug=${slug}`,access_token)?.subscribe((data) => {
        res(data);
      }), (err:any) => {
        rej(err);
      }
    })
  }

  getOtherEvents(){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.getDataFromAPIWithoitParam(`api/v1/webpages/webpage?slug=events`,access_token)?.subscribe((data) => {
        res(data);
      }), (err:any) => {
        rej(err);
      }
    })
  }

  getOtherEventsDetails(ids: number[]) {
    let access_token = localStorage.getItem('acces-token');
    const queryString = ids.map(id => `ids[]=${id}`).join('&');  
    return new Promise((res, rej) => {
      this.getDataFromAPIWithoitParam(`api/v1/webpages/ids?${queryString}`, access_token)?.subscribe((data) => {
        res(data);
      }, (err: any) => {
        rej(err);
      });
    });
  }

  getEventList(regionalCode:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.getDataFromAPIWithoitParam(`api/v1/webpages/all?page_type=events_item&regionalActive=${regionalCode}`,access_token)?.subscribe((data) => {
        res(data);
      }), (err:any) => {
        rej(err);
      }
    })
  }

  getEvents(slug:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.getDataFromAPIWithoitParam(`api/v1/webpages/webpage?slug=${slug}`,access_token)?.subscribe((data) => {
        res(data);
      }), (err:any) => {
        rej(err);
      }
    })
  }

  getEventsById(slug:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.getDataFromAPIWithoitParam(`api/v1/webpages/webpage?slug=${slug}`,access_token)?.subscribe((data) => {
        res(data);
      }), (err:any) => {
        rej(err);
      }
    })
  }

  getLifeAtRWC(slug:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.getDataFromAPIWithoitParam(`api/v1/webpages/webpage?slug=${slug}`,access_token)?.subscribe((data) => {
        res(data);
      }), (err:any) => {
        rej(err);
      }
    })
  }

  getManageData(slug:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.getDataFromAPIWithoitParam(`api/v1/webpages/webpage?slug=${slug}`,access_token)?.subscribe((data) => {
        res(data);
      }), (err:any) => {
        rej(err);
      }
    })
  }

  getFooter(slugs:string){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.getDataFromAPIWithoitParam(`api/v1/webpages/webpage?slug=${slugs}`,access_token)?.subscribe((data) => {
        res(data);
      }), (err:any) => {
        rej(err);
      }
    })
  }

  getPalaceData(slug:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.getDataFromAPIWithoitParam(`api/v1/webpages/webpage?slug=${slug}`,access_token)?.subscribe((data) => {
        res(data);
      }), (err:any) => {
        rej(err);
      }
    })
  }

  getBrochuresGuidesData(slug:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.getDataFromAPIWithoitParam(`api/v1/webpages/webpage?slug=${slug}`,access_token)?.subscribe((data) => {
        res(data);
      }), (err:any) => {
        rej(err);
      }
    })
  }

  getBlogsData(pageNo:any, year:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.getDataFromAPIWithoitParam(`api/v1/webpages/all?page_type=blog_item&page=${pageNo}&year=${year}&limit=4`,access_token)?.subscribe((data) => {
        res(data);
      }), (err:any) => {
        rej(err);
      }
    })
  }

  getTopStoriesData(year:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.getDataFromAPIWithoitParam(`api/v1/webpages/all?page_type=blog_item&year=${year}&limit=16`,access_token)?.subscribe((data) => {
        res(data);
      }), (err:any) => {
        rej(err);
      }
    })
  }

  getBlogDetails(slug:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.getDataFromAPIWithoitParam(`api/v1/webpages/webpage?slug=${slug}`,access_token)?.subscribe((data) => {
        res(data);
      }), (err:any) => {
        rej(err);
      }
    })
  }

  getBlogSliderData(){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.getDataFromAPIWithoitParam(`api/v1/webpages/all?page_type=blog_item&limit=25`,access_token)?.subscribe((data) => {
        res(data);
      }), (err:any) => {
        rej(err);
      }
    })
  }
  getAnnouncement(){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.getDataFromAPIWithoitParam('api/v1/homepage/get-announcement',access_token)?.subscribe((data) => {
        res(data);
      }), (err:any) => {
        rej(err);
      }
    })
  }

  getHomeExperiences(slug:any){
    let access_token = localStorage.getItem('acces-token')
    return new Promise((res, rej) => {
      this.getDataFromAPIWithoitParam(`api/v1/webpages/webpage?slug=${slug}`,access_token)?.subscribe((data) => {
        res(data);
      }), (err:any) => {
        rej(err);
      }
    })
  }

}




