import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer-bar',
  templateUrl: './footer-bar.component.html',
  styleUrls: ['./footer-bar.component.scss'],
})
export class FooterBarComponent  implements OnInit {
  currentYear: any;
  billsOfRight = environment.billsOfRight;
  privacyPolicy = environment.privacyPolicy;
  cookiePolicy = environment.cookiePolicy;
  termsOfUse = environment.termsOfUse;
  passageTerms = environment.passageTerms;
  termsandconditions = environment.termsandconditions;
  LanguageId: any = '1';

  constructor(private router: Router) {
    this.currentYear = new Date().getFullYear();
   }

  ngOnInit() {
    this.LanguageId = localStorage.getItem('LanguageID');
  }
  
  openTermcConditions(event:Event){
    event.preventDefault();
    this.router.navigateByUrl('/terms-conditions');
  }
  handleClick(event : Event, url : string='',target =''){
    event.preventDefault();
    if(url.length > 0 && target.length == 0){
      window.location.href = url;
    }else if(target.length > 0){
      window.open(url, '_blank');
    }else {
      // Do nothing
    }
  }

}
