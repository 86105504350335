import { BreakpointState } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { GetDataService } from 'src/providers/get-data.service';
import { PostDataService } from 'src/providers/post-data.service';
import { ScreenServiceService } from 'src/providers/screen-service.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { IpAddressService } from 'src/providers/ip-address.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent  implements OnInit {
  menus: any = [];
  showLang : boolean = false;
  showMobileMenu : boolean = false;
  isBelowLg: boolean = false;
  showsubMenu: boolean = false;
  @Output() menuEvent = new EventEmitter<boolean>();
  @Input() isFixed : boolean = false;
  selectedLanuageId : any;
  logoURL : any;
  knowledgeBook = environment.Know_Before_You_Sail;
  cruisePrepare = environment.Prepare_For_Your_Cruise;
  genting_reward = environment.genting_reward;
  bookCruise = environment.book_cruise;
  checkin = environment.checkin;
  contactUs = environment.contactUs;
  displayManage: boolean = false;
  domain_name = environment.domain_name;
  constructor( private getData: GetDataService, private postData: PostDataService, private screenService: ScreenServiceService,
    private router: Router,private translateService: TranslateService,private cookieService: CookieService, private ipServices : IpAddressService) {
    this.screenService.isBelowLg().subscribe((isBelowLg: BreakpointState) => {
      this.isBelowLg = isBelowLg.matches;
    });
   }

  async ngOnInit() {
    let access_token = localStorage.getItem('acces-token')
    let LangId = localStorage.getItem('LanguageID');
    let ip = localStorage.getItem('current_ip');
    let rwc_usr_ip_source = this.cookieService.get('rwc_usr_ip_source');
    if(!rwc_usr_ip_source){
      // await this.ipServices.storeCookies();
    }
    this.selectedLanuageId = LangId
    if(LangId === '2'){
      this.logoURL = '../../assets/logo-cn.png';
    }else if(LangId === '3'){
      this.logoURL = '../../assets/logo-sc.png'
    }else{
      this.logoURL = '../../assets/logo.png';
    }
    if(access_token != null){
      if(ip != null){
        this.getHomeMenuData();
      }else{
        // await this.postData.getLocation().then(async(id:any) => {
          await this.getHomeMenuData();
        // });
      }
    }else{
      await this.postData.loginUser().then(async(res:any) => {
        localStorage.setItem('acces-token', res.access_token);
        // await this.postData.getLocation().then(async(id:any) => {
          await this.getHomeMenuData();
        // });
      })
    }
  }

  async getHomeMenuData(){
    await this.getData.getMenu().then((res:any) => {
      const tempMenu = res.map((item:any) => {
        return { ...item, selectedMenu : false };
      });
      this.menus = tempMenu;
    })
  }

  handleChange(menu_name:any) {
    let temp = this.menus.map((menu:any) => {
      if (menu_name === menu.menu_name) {
        return { ...menu, selectedMenu: !menu.selectedMenu };
      }
      return menu;
    });
    this.menus = temp;
  };
  handleMenuManage(){
    this.displayManage = !this.displayManage;
  }

  openLangModal(event: Event){
    event.preventDefault();
    this.showLang = true;
  }
  closeModal(){
    this.showLang = false;
  }

  openMobileMenu(){
    this.showMobileMenu = true;
    this.menuEvent.emit(this.showMobileMenu);
  }
  closeMobileMenu(){
    this.showMobileMenu = false;
    this.menuEvent.emit(this.showMobileMenu);
  }
  enableSubMenu(){
    this.showsubMenu = true;
  };
  refreshPage(event:Event) {
    event.preventDefault()
    // Get the current route and navigate to it
    const currentRoute = this.router.url;
    if(currentRoute === '/home'){
      window.location.reload();
    }else{
      this.router.navigate(['home']);
    }
    
  }
  changeLanguage(id:any,event:Event){
    if(id === '4'){
      localStorage.setItem('isArabic', 'yes');
    }else{
      localStorage.setItem('isArabic', 'no');
    }
    event.preventDefault();
    localStorage.setItem('LanguageID', id);
    if(id === '1'){
      let language = 'en';
      localStorage.setItem('Language', 'EN');
      this.cookieService.set('rwc_site_lang', 'EN',1, '/', this.domain_name);
      this.translateService.use(language);
      this.cookieService.set('rwc_site_lang_variant','null',1, '/', this.domain_name)
    }else if(id === '2'){
      let language = 'tr';
      this.translateService.use(language);
      this.cookieService.set('rwc_site_lang', 'CN',1, '/', this.domain_name);
      localStorage.setItem('Language', 'CN');
      this.cookieService.set('rwc_site_lang_variant','null',1, '/', this.domain_name)
    }else if(id === '3'){
      let language = 'si';
      this.translateService.use(language);
      this.cookieService.set('rwc_site_lang', 'CN',1, '/', this.domain_name);
      this.cookieService.set('rwc_site_lang_variant','SC',1, '/', this.domain_name)
      localStorage.setItem('Language', 'CN');
    }else{
      let language = 'ar';
      document.documentElement.dir = 'rtl';
      localStorage.setItem('Language', 'AR');
      this.cookieService.set('rwc_site_lang', 'AR',1, '/', this.domain_name);
      this.translateService.use(language);
      this.cookieService.set('rwc_site_lang_variant','AR',1, '/', this.domain_name)
    }
    this.closeModal();
   
    window.location.reload();
  }
  handleClick(event : Event, url : string='',target =''){
    event.preventDefault();
    if(url.length > 0 && target.length == 0){
      window.location.href = url;
    }else if(target.length > 0){
      window.open(url, '_blank');
    }else {
      // Do nothing
    }
  }
}
