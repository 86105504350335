<div class="modal show modal-flash-sale view-price-guest" tabindex="-1" role="dialog"
  aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="popup-title" translate>Pricing Details</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
        (click)="closeModal()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="staterooms-guest-room" *ngFor="let guestD of guestPriceDetails;let i = index">
          <div class="staterooms-guest-room-item">
            <div class="title">{{"Room" | translate}} {{i+1}} - {{guestD.category_code}} ({{guestD.guest_price.length}} {{(guestD.guest_price.length == 1 ? 'Guest' : 'Guests') | translate}})</div>
          </div>
          <div class="staterooms-guest-room-item staterooms-guest-room-board"
            *ngFor="let guest of guestD.guest_price;let i = index">
            <div class="title mb-3">{{"Guest" | translate}} {{i+1}}</div>
            <div class="staterooms-guest-room-board-item">
              <span translate>Cruise Fare</span>
              <span class="price">{{languageID == 1 ? CurrencyCode_en : languageID == 4 ?
                CurrencyCode_arabic : languageID == 2 ? CurrencyCode_tc :
                CurrencyCode_sc}} {{formatNumber(guest.cabin_charge)}}</span>
            </div>
            <div class="staterooms-guest-room-board-item">
              <span translate>Discount</span>
              <span class="price">- {{languageID == 1 ? CurrencyCode_en : languageID == 4 ?
                CurrencyCode_arabic : languageID == 2 ? CurrencyCode_tc :
                CurrencyCode_sc}} {{formatNumber(guest.discountPrice)}}</span>
            </div>
            <div class="staterooms-guest-room-board-item">
              <span translate>Port Charges</span>
              <span class="price">{{languageID == 1 ? CurrencyCode_en : languageID == 4 ?
                CurrencyCode_arabic : languageID == 2 ? CurrencyCode_tc :
                CurrencyCode_sc}} {{formatNumber(guest.port_charge)}}</span>
            </div>
            <div *ngIf="guest.ancillary_charge" class="staterooms-guest-room-board-item">
              <span translate>Ancillary Charges</span>
              <span class="price">{{languageID == 1 ? CurrencyCode_en : languageID == 4 ?
                CurrencyCode_arabic : languageID == 2 ? CurrencyCode_tc :
                CurrencyCode_sc}} {{formatNumber(guest.ancillary_charge)}}</span>
            </div>
            <div class="staterooms-guest-room-total">
              <span translate>Guest Total</span>
              <span class="price">{{languageID == 1 ? CurrencyCode_en : languageID == 4 ?
                CurrencyCode_arabic : languageID == 2 ? CurrencyCode_tc :
                CurrencyCode_sc}} {{formatNumber(guest.totalPrice)}}</span>
            </div>
          </div>
        </div>

        <div class="staterooms-guest-room-footer">
          <div class="total-price">
            <span translate>Total price <abbr *ngIf="!isPGratuityPresent">({{"exclude gratuities" | translate}})</abbr></span>
            <span class="price">{{languageID == 1 ?
              CurrencyCode_en : languageID == 4 ? CurrencyCode_arabic :
              languageID == 2 ? CurrencyCode_tc : CurrencyCode_sc}} {{formatNumber(TotalAmount)}}</span>
          </div>
          <div class="text-center view-cancellation-policy">
            <a href="https://rwcruises.com/terms-and-conditions" target="_blank"
              class="text-uppercase pt-3 d-inline-block" translate>View Amendments & Cancellation policy</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>