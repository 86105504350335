import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, from } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { PostDataService } from './post-data.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private postData : PostDataService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const excludedIPUrl = 'https://ipinfo.io/json';
    const excludedUrl = "https://nominatim.openstreetmap.org"

    // Check if the request URL matches the excluded URL and if the method is JSONP
    if (!request.url.startsWith(excludedUrl) || !request.url.startsWith(excludedIPUrl) || request.method !== 'JSONP') {
      const token = localStorage.getItem('acces-token');
      if (token && !request.headers.has('Authorization')) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer  ${token}`
          }
        });
      }
    }

    return next.handle(request).pipe(
      catchError(err => {
        if (err.status === 401) {
          return this.handle401Error(request, next);
        } else {
          const error = err.error?.message || err.statusText;
          console.error(err);
          return throwError(() => error);
        }
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next('');

      return from(this.postData.loginUser()).pipe(
        switchMap((response: any) => {
          this.isRefreshing = false;
          const newToken = response.access_token;
          this.refreshTokenSubject.next(newToken);

          localStorage.setItem('acces-token', newToken);

          return next.handle(this.addToken(request, newToken));
        }),
        catchError((err) => {
          this.isRefreshing = false;
          localStorage.clear();
          console.error('Login failed', err);
          return throwError(err);
        })
      ) as Observable<HttpEvent<any>>;
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != ''),
        take(1),
        switchMap(token => {
          return next.handle(this.addToken(request, token));
        })
      );
    }
  }

  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer  ${token}`
      }
    });
  }
}
