import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { countryJson } from 'src/providers/country';
import { GetDataService } from 'src/providers/get-data.service';
import { PostDataService } from 'src/providers/post-data.service';
import { environment } from 'src/environments/environment';
import { locationCountryJson } from 'src/config/LocationMaster';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CountryISO, SearchCountryField, } from 'ngx-intl-tel-input';
declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  countryList: any = countryJson;
  newsEmail: any;
  newsCountry: any = 'Singapore';
  @ViewChild('NewsLetterForm') NewsLetterForm: any;
  menus: any = [];
  currentYear: any;
  billsOfRight = environment.billsOfRight;
  privacyPolicy = environment.privacyPolicy;
  cookiePolicy = environment.cookiePolicy;
  termsOfUse = environment.termsOfUse;
  passageTerms = environment.passageTerms;
  termsandconditions = environment.termsandconditions;
  bookCruise = environment.book_cruise;
  checkin = environment.checkin;
  contactUs = environment.contactUs;
  careers = environment.careers;
  mediaResources = environment.mediaResources;
  partnerResource = environment.partnerResource;
  LanguageId: any = 1;
  @Input() isHomePage: string = 'No';
  preferredCountries: CountryISO[] = [CountryISO.Singapore];
  // SearchCountryField = SearchCountryField;
  // CountryISO = CountryISO;
  phoneForm: FormGroup;
  flageCode: any = 'SG';
  location_code: any;
  continent_flag : any;
  constructor(
    private postData: PostDataService,
    private toastController: ToastController,
    private getData: GetDataService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.currentYear = new Date().getFullYear();
    this.phoneForm = this.fb.group({
      phone: ['', [Validators.required]]
    });
  }

  async ngOnInit() {
    this.LanguageId = localStorage.getItem('LanguageID');
    let access_token = localStorage.getItem('acces-token');
    let ip = localStorage.getItem('current_ip');
    this.location_code = localStorage.getItem('location_code')
    // this.continent_flag = 
    //   locationCountryJson.filter((item => item.continent === 'Gulf')).some((country: any) => country.location_code === this.location_code) 
    //   ? 'Gulf' : 'Asia'
    if (access_token != null) {
      if (ip != null) {
        this.getHomeMenuData();
        await this.getCountryCode();
      } else {
        // await this.postData.getLocation().then(async (id: any) => {
          await this.getHomeMenuData();
          await this.getCountryCode();
        // });
      }
    } else {
      await this.postData.loginUser().then(async (res: any) => {
        localStorage.setItem('acces-token', res.access_token);
        // await this.postData.getLocation().then(async (id: any) => {
          await this.getHomeMenuData();
          await this.getCountryCode();
        // });
      })
    }
    $('.iti--allow-dropdown input').attr('readonly', 'true');
  }
  getCountryCode() {
    let locId = localStorage.getItem('LocationID');
    let countries = locationCountryJson.find((item: any) => item.id === Number(locId));
    let selectedCountry = countryJson.find((item: any) => item.name.trim().toLowerCase() === countries?.location_name.trim().toLowerCase());
    this.newsCountry = selectedCountry?.name.trim();

    let flageCode = countryJson.find((item: any) => item.name.trim() === this.newsCountry.trim());
    this.flageCode = flageCode?.code
  }

  onCountryChange(event: any) {
    let name = event.name.split('(');
    this.newsCountry = name[0].trim();
    this.phoneForm.patchValue({
      phone: this.newsCountry
    });
  }


  async getHomeMenuData() {
    await this.getData.getMenu().then((res: any) => {
      this.menus = res;
    })
  }

  async subscribe(formData: any) {
    if (formData.valid) {
      let params = {
        "location_name": this.newsCountry.number, // this.phoneForm.value.number // formData.value.country,
        "email": formData.value.email
      }
      await this.postData.sendNewsLetterData(params).then((res: any) => {
        this.presentToast(res.message);
        formData.resetForm();
        this.getCountryCode();
        // this.newsCountry = 'Singapore'
      })
    }
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 1500,
      position: 'top',
      cssClass: 'custom-toast'
    });

    await toast.present();
  }

  openTermcConditions(event:Event) {
    event.preventDefault()
    this.router.navigateByUrl('/terms-conditions');
  }
  handleClick(event : Event, url : string='',target =''){
    event.preventDefault();
    if(url.length > 0 && target.length == 0){
      window.location.href = url;
    }else if(target.length > 0){
      window.open(url, '_blank');
    }else {
      // Do nothing
    }
  }

}
