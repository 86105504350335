import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';

@Component({
  selector: 'app-chat-icon',
  templateUrl: './chat-icon.component.html',
  styleUrls: ['./chat-icon.component.scss'],
})
export class ChatIconComponent  implements OnInit {

  constructor(private renderer: Renderer2, private el: ElementRef) { }

  ngOnInit(): void {
    setTimeout(() => {
      const chatIconHolder = this.el.nativeElement.querySelector('#chat-icon-holder');
      this.renderer.setStyle(chatIconHolder, 'transition', 'bottom 1s');
      this.renderer.setStyle(chatIconHolder, 'bottom', '30px');
    }, 1000);
  }

}
