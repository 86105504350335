import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-promo-modal',
  templateUrl: './promo-modal.component.html',
  styleUrls: ['./promo-modal.component.scss'],
})
export class PromoModalComponent  implements OnInit {
  @Output() close = new EventEmitter<void>();
  @Input() title !: any;
  @Input() description !: any;
  constructor() { }

  ngOnInit() {}
  
  closeModal() {
    this.close.emit();
  }
}
